
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon } from '@mui/material';
import { AutoCompleteFazenda, IVFormErrors, VFieldMoney, VForm, VTextField, useVForm } from '../../../shared/forms';
import { PontoService } from '../../../shared/services/api/rh/ponto/PontoService';
import * as yup from 'yup';

interface IFormData {
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  idfazenda: string;
  fazenda: string;
  semana: string;
  dia: string;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'fazenda'>> = yup.object().shape({
  ponto: yup.string().required().matches(/^[AFPDM]$/, 'Permitir apenas estas letras: A, F, P, D, M').min(1).max(1),
  idfazenda: yup.string().required().min(3).max(150),
  semana: yup.string().required().min(3).max(40),
  dia: yup.string().required().min(1).max(2),
  pistola: yup.number().optional().default(0).max(1).min(0),
  horaextra: yup.number().optional().default(0),
  bomba: yup.number().optional().default(0).max(1).min(0),
  almoco: yup.number().optional().default(0).max(1).min(0),
  janta: yup.number().optional().default(0).max(1).min(0),
  cafe: yup.number().optional().default(0).max(1).min(0),
});

export const DialogPonto: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        PontoService
          .updateById(props.id, dadosValidados)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              onClose(valueProp);
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    if(props.id !== ''){
      setIsLoading(true);
      PontoService.getById(props.id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            alert('o erro 1');
          } else {
            formRef.current?.setData(result);
            setStatus(result.status);
          }
        });
    }
  }, [props.id]);

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = () => {
    if (confirm('Realmente deseja apagar?')) {
      PontoService.deleteById(props.id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            onClose(valueProp);
          }
        });
    }
  };

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Editar Ponto</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column"  >
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='dia'
                      label='Dia'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='semana'
                      label='Semana'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='ponto'
                      label='Ponto'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='horaextra'
                      label='Hora Extra'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>          
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='bomba'
                      label='Bomba'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>    
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='pistola'
                      label='Pistola'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>    
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='cafe'
                      label='Café'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>    
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='almoco' 
                      label='Almoço'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>  
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='janta'
                      label='Janta'
                      disabled={status === false ? isLoading : true}
                    />
                  </Grid>         
                  <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                    <AutoCompleteFazenda isExternalLoading={status === false ? isLoading : true}  />
                  </Grid>                        
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button disabled={status === false ? isLoading : true}  color='error' startIcon={<Icon>delete</Icon>}  variant="contained"  onClick={handleDelete}>Excluir</Button>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button disabled={status === false ? isLoading : true} variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}; 