/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Box, FormGroupClassKey, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, IVFormErrors, VSwitch  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { CultivosService } from '../../../shared/services/api/cadastros/CultivosService';
import { Environment } from '../../../shared/environment';
import Snackbar, { SnackbarCloseReason, SnackbarOrigin  } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface IFormData {
  descricao: string;
  status: boolean;
}

interface IUpdateImg {
  urlimg: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  descricao: yup.string().optional().default(''),
  status: yup.boolean().optional().default(true),
});


export const DetalheDeCultivos: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [urlimg, setUrlimg] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error'  | 'info'  | 'success'>('warning');  
  
  
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);

      CultivosService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            navigate('/cultivo');
          } else {
            setDescricao('Cultivo: '+ result.descricao);
            formRef.current?.setData(result);
            if (result.urlimg != null) {
              setUrlimg(result.urlimg);
              setImage(Environment.URL_IMGCULTIVOS+result.urlimg);
            } 
          }
        });
    } else {
      formRef.current?.setData({
        descricao: '',
        obs: '',
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'novo') {
          CultivosService
            .create(selectedImage, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                setTms('error');
                setMsg(result.message);
                setOpen(true);
              } else {
                setTms('success');
                setMsg('Cultivo Salvo com Sucesso');
                setOpen(true);
                if (isSaveAndClose()) {
                  navigate('/cultivos');
                } else {
                  navigate(`/cultivos/detalhe/${result}`);
                }
              }
            });
        } else {
          CultivosService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                setTms('error');
                setMsg(result.message);
                setOpen(true);
              } else {
                if (isSaveAndClose()) {
                  navigate('/cultivos');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      CultivosService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpen(true);
          } else {
            setTms('success');
            setMsg('Registro apagado com sucesso!');
            setOpen(true);
            navigate('/cultivos');
          }
        });
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files?.[0]);
    const imagen =  event.target.files?.[0];  
    if (imagen) {
      const imageUrl = URL.createObjectURL(imagen);
      setImage(imageUrl);
    }
    if (id !== 'novo') {
      CultivosService.updateByIdImg(id, imagen, urlimg)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTms('success');
            setMsg('Imgaem alterada com sucesso!');
            setOpen(true);
            navigate('/cultivos'); 
          }
        });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Cultivo' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/cultivos')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/cultivos/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open}  autoHideDuration={900} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={tms}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>;
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Dados</Typography>
            </Grid>

            <Grid container item direction="row" spacing={2} rowSpacing={1} >

              <Grid item xs={12} sm={12} md={2} lg={2} xl={1} >
                <Box width="100%" marginRight={5} > 
                  <input
                    type="file"
                    id="avatar-input"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="avatar-input">
                    <Avatar 
                      variant='rounded'
                      alt='F'
                      src={image || undefined}
                      sx={{ width: '100%', height: 150 }}
                    />
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VTextField
                    fullWidth
                    size='small'
                    name='descricao'
                    disabled={isLoading}
                    label='Descricao'
                    onChange={e => setDescricao('Cultivo: '+e.target.value)}
                  />
                </Grid>

                <Grid item marginTop={2} xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VTextField
                    fullWidth
                    name='obs'
                    disabled={isLoading}
                    multiline
                    rows={2}
                    label='Observações'
                    onChange={e => setDescricao('Cultivo: '+e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                {(id != 'novo') && (
                  <VSwitch
                    name='status'
                    label='Status'
                    fdisabled={isLoading}
                    aliamento='start'
                  />

                )}
              </Grid>
            </Grid>              
          </Grid>  
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};