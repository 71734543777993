/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


interface ContasPagar {
  dataemissao: Date;
  vencimento: Date;
  nome: string;
  cpf: string;
  historico: string;
  fazenda: string;
  numerodoc: string;
  valorparcela: number; 
  acrescimo: number;
  valorpago: number;
  desconto: number; 
  valorpagar: number;
}

interface IParceirosPagar {
  idparceiro: string;
  nome: string;
  cpf: string;
  valorparcela: number; 
  acrescimo: number;
  valorpago: number;
  desconto: number; 
  valorpagar: number;
}

interface EntryPagar {
  contas: IParceirosPagar;
  contaspagar: ContasPagar[];
}

async function RelatorioContasPagarAgrupados(data: EntryPagar[], periodo: string){
  const Tparcela = data.reduce((acc, item) => acc + item.contas.valorparcela, 0);
  const Tacrescimo = data.reduce((acc, item) => acc + item.contas.acrescimo, 0);
  const Tdesconto = data.reduce((acc, item) => acc + item.contas.desconto, 0);
  const Tvalorpago = data.reduce((acc, item) => acc + item.contas.valorpago, 0);
  const Tvalorpagar = data.reduce((acc, item) => acc + item.contas.valorpagar, 0);

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }


  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 10, 20, 10],
    // pageOrientation: 'landscape',
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],  
          widths: [48, 800],  
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },  
              {
                width: 800,
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Contas a Pagar', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      { text: 'Período de: '+periodo,  alignment: 'left', fillColor: '#dddddd', fontSize: 8},     
      data.map(e => {
        const { contas, contaspagar } = e;
        const TValorpagar = contaspagar.reduce((acc, item) => acc + item.valorpagar, 0);
        const TPago = contaspagar.reduce((acc, item) => acc + item.valorpago, 0);
        const TDesconto = contaspagar.reduce((acc, item) => acc + item.desconto, 0);
        const TAcrescimo = contaspagar.reduce((acc, item) => acc + item.acrescimo, 0);
        const TParcela = contaspagar.reduce((acc, item) => acc + item.valorparcela, 0);
        const rows = contaspagar.map((pagar) => {
          return [
            {text: moment(pagar.vencimento).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},
            {text: pagar.numerodoc, alignment: 'left',  fontSize: 7},
 
            {text: pagar.fazenda, alignment: 'left',  fontSize: 7},
            {text: pagar.historico, alignment: 'left',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(pagar.valorparcela), alignment: 'right', fontSize: 8},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(pagar.acrescimo), alignment: 'right',  fontSize: 8},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(pagar.desconto), alignment: 'right',  fontSize: 8},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(pagar.valorpago), alignment: 'right',  fontSize: 8},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(pagar.valorpagar), alignment: 'right',  fontSize: 8},
          ]; 
        });  

        return [
          [             
            [                            
              {  
                margin: [0, 5, 0, 0],    
                table: { 
                  body: [
                    [
                      {text: 'Parceiros: '+ e.contas.nome+'   '+(e.contas.cpf.length == 11 ? (
                        'CPF: '+e.contas.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                      ) : (
                        'CNPJ: '+e.contas.cpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                      )), bold: true, fontSize: 9, alignment: 'left', border: [false, false, false, false]},
                    ],
                  ] 
                },     
              },
              {   
                table:{
                  widths: [45, 48, 40, 160, 40, 32, 35, 40, 40],
                  body: [
                    [
                      {text: 'Vencimento', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
                      {text: 'Número', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
                      {text: 'Fazenda', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
                      {text: 'Histórico', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
                      {text: 'Parcela', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
                      {text: 'Acrés.', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
                      {text: 'Desconto', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
                      {text: 'Valor Pago', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
                      {text: 'Valor Pagar', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
                    ],
                    ...rows,
                  ]
                },       
              },   
              {     
                table: {
                  widths: [45, 48, 40, 160, 40, 32, 35, 40, 40],
                  body: [
                    [
                      {text: '', border: [false, false, false, false]},
                      {text: '', border: [false, false, false, false]},
                      {text: '', border: [false, false, false, false]},
                      {text: 'Totais',  fontSize: 8, alignment: 'right',  border: [false, false, false, false]},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TParcela), border: [true, false, false, true], alignment: 'right', fontSize: 7},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TAcrescimo), border: [true, false, false, true], alignment: 'right', fontSize: 7},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TPago), border: [true, false, false, true], alignment: 'right', fontSize: 7},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TDesconto), border: [true, false, false, true], alignment: 'right', fontSize: 7},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TValorpagar), border: [true, false, true, true], alignment: 'right', fontSize: 7},
                    ],
                    [
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},  
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},  
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                    ]
                  ]
                },       
              }                                          
            ]
          ]                           
            
        ];
      }     
      ),
      {
        table: {
          widths: [45, 48, 40, 160, 40, 32, 35, 40, 40],
          body: [
            [
              { text: '',  border: [false, false, false, false]}, 
              { text: '',  border: [false, false, false, false]}, 
              { text: '',  border: [false, false, false, false]}, 
              { text: 'Totais', alignment: 'right', fontSize: 8, bold: true,  border: [false, false, false, false]},   
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tparcela), fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tacrescimo), fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tdesconto), fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tvalorpago), fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tvalorpagar), fontSize: 8, alignment: 'right', border: [false, false, false, false]},
            ],
          ]
        },       
      }
    ]
  };
  pdfMake.createPdf(documentDefinition).open();
}
export default RelatorioContasPagarAgrupados; 

