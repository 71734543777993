import { useState,useEffect, useCallback, useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { IListagemValeresumo, ValeresumoService,IListagemValesgrafico} from '../../../shared/services/api/rh/colaborador/valesresumo';
import { FerramentasDaListagemvaleresumo } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemvaleresumo';
import NavTabs from './NavTabs';
import { Api } from '../../../shared/services/api/axios-config';
import { Environment } from '../../../shared/environment';
import { useSearchParams } from 'react-router-dom';

export const Listagemcolaboradorvale: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dadosDoGrafico, setDadosDoGrafico] = useState<IListagemValesgrafico[]>([]);
  const [rows, setRows] = useState<IListagemValeresumo[]>([]);
  const [nomeColaborador, setNomeColaborador] = useState(''); 
  const idfun = new URLSearchParams(location.search).get('idfun');
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
 
  const getCurrentYear = () => {
    return new Date().getFullYear().toString();
  };

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const [anoSelecionado, setAnoSelecionado] = useState<string>(getCurrentYear());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.get(`/funcionarios/${idfun}`);
        const colaboradorData = response.data;
        setNomeColaborador(colaboradorData.nome);         
      } catch (error) {
        alert('Erro ao obter dados do colaborador.');
      }
    };
    fetchData();
  }, [idfun]);

  const HandleSelect = useCallback((ano: string) => {
    if (ano != null) {
      setIsLoading(true);
      ValeresumoService.getAll(pagina, idfun!, ano)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            const formattedData = result.grafico.map(item => ({
              mes: item.mes as number, 
              periodo: item.periodo,
              total: item.total as number, 
            }));
            setTotalCount(result.totalCount);
            setRows(result.detalhes);
            setDadosDoGrafico(formattedData);
          }
        });
    }
  }, [idfun, pagina]); 

  useEffect(() => {
    HandleSelect(anoSelecionado); 
  }, [anoSelecionado, HandleSelect]); 
  
  return (
    <LayoutBaseDePagina 
      titulo={ nomeColaborador }
      barraDeFerramentas={
        <FerramentasDaListagemvaleresumo
          aoSelecionar={(ano) => {
            if (ano) {
              setAnoSelecionado(ano); 
              HandleSelect(ano); 
            }
          }}
          anoInicial={anoSelecionado}
        />
      }
    > 
      <NavTabs
        id={idfun!}
        case={4}   
      />
      <Box margin={1} height={'100%'} display="flex" flexDirection="column" gap={2} >
        <Box  height={'40%'}  display="flex">
          {dadosDoGrafico && dadosDoGrafico.length > 0 && (
            <BarChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: dadosDoGrafico.map((item) => item.periodo),
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  data: dadosDoGrafico.map((item) => item.total),
                },
              ]}
              width={1500}
              height={250}
            />
          )}
        </Box>
        <Box gap={3} height={'60%'}>
          <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Período</TableCell>
                  <TableCell>Histórico</TableCell>
                  <TableCell align='right'>Valor</TableCell>
                  <TableCell>Financeiro</TableCell>
                  <TableCell>Forma de Desconto</TableCell>
                  <TableCell>Parcela</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.idvale} 
                    hover
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>{row.periodo}</TableCell>
                    <TableCell>{row.historico}</TableCell>
                    <TableCell align='right'>{row.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                    <TableCell>{row.financeiro}</TableCell>
                    <TableCell>{row.tipodesconto}</TableCell>
                    <TableCell>{row.parcela}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {totalCount === 0 && !isLoading && (
                <caption>{Environment.LISTAGEM_VAZIA}</caption>
              )}
              <TableFooter>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <LinearProgress variant='indeterminate' />
                    </TableCell>
                  </TableRow>
                )}
                {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS1) && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Pagination
                        page={pagina}
                        count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS1)}
                        onChange={(_, newPage) => setSearchParams({idfun: idfun!, pagina: newPage.toString() }, { replace: true })}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box> 
    </LayoutBaseDePagina>
  ); 
};