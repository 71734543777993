/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField, VForm, useVForm, VTextFieldData, VFieldMoney, AutoCompleteParceiro, IVFormErrors, AutoCompleteFazenda, AutoCompleteCultivo, AutoCompletePivot } from '../../../shared/forms';
import { FerramentasDeDetalheNfe } from '../../../shared/components';
import dayjs from 'dayjs';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { DialogSaida } from './DialogSaida';
import { v4 as uuidv4 } from 'uuid';
import { AutoCompleteOperacoes } from '../../../shared/forms/AutoCompleteOperacoes';
import { EstoqueOperacoesService } from '../../../shared/services/api/estoque/estoqueOperacoes/EstoqueOperacoesService';
import { SaidaService } from '../../../shared/services/api/estoque/saida/SaidaService';
import { FerramentasDeDetalheEntradaSaida } from '../../../shared/components/ferramentas-de-detalhe/FerramentasDeDetalheEntradaSaida';
import PDFSaida from './Impressaosaida';

interface IFormData {
  idfazenda?: string | null;
  idoperacoes: string;
  idparceiro?: string | null;
  idcultivo?: string | null;
  idpivot?: string | null;
  datalan: Date;
  dataoperacao: Date;
  total: number;
  obs: string;

  venda: boolean;
}

interface IitensSaidaDialog{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;
  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean;
}
          
interface IItensids{
  iditens: string;            
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfazenda: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido').when('venda', {
    is: false,
    then: () => yup.string().required('Campo obrigatório').test('valid-parceiro', 'O campo é obrigatório', function (value) {
      if (!value) return false; 
        
      return true;
    })
  }),
  venda: yup.boolean().required(),
  idoperacoes: yup.string().required(),
  idparceiro: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido').when('venda', {
    is: true,
    then: () => yup.string().required('Campo obrigatório').test('valid-parceiro', 'O campo é obrigatório', function (value) {
      if (!value) return false; 
      return true;
    })
  }),
  idcultivo: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idpivot: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  datalan: yup.date().required(),
  dataoperacao: yup.date().required(),
  // dataoperacao: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
  //   const currentDate = new Date();
  //   if (!value || value > currentDate || value.getDate() < currentDate.getDate()-10 ) return false; 
  //   return true;
  // }),
  obs: yup.string().optional().default(''),
  total: yup.number().required(),
});

export const DetalheDaSaida: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [statuss, setStatuss] = useState(false);
  const [iditens, setIditens] = useState('');
  const [rows, setRows] = useState<IitensSaidaDialog[]>([]);
  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  const [dadositens, setDadositens] = useState<IitensSaidaDialog>();
  const guuid = uuidv4();
  const [venda, setvenda] = useState(false);
  const [edit, setEdit] = useState(false);
  const [idfazenda, setIdfazenda] = useState<string>(); 
  const [nome, setNome] = useState('');
  const [idparceiro, setIdParceiro] = useState('');
  const handleuuidfazenda = (uuid: string | undefined) => {
    setIdfazenda(uuid!);
  };

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      SaidaService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/saidaestoque');
          } else {
            setIdParceiro(result.idparceiro);
            setNome(result.nome);
            formRef.current?.setData(result);
            setRows(result.itens);

            EstoqueOperacoesService.getById(result.idoperacoes)
              .then((result) => {
                if (result instanceof Error) {
                  alert(result.message);
                  navigate('/saidaestoque');
                } else {
                  setvenda(result.venda);
                }
              });            
            // if (result.datalan) {
            //   const dataLanDayjs = dayjs(result.datalan);
            //   const dataAtual = dayjs();
            //   if (dataAtual.diff(dataLanDayjs, 'days') >= 10) {
            //     setStatuss(true);
            //   }
            // }
            if(result.itens.length != 0){
              setEdit(true);
            }else{
              setEdit(false);
            }   
          }
        });    
    } else {
      formRef.current?.setData({
        idsaida: '',   
        idfazenda: '',
        idoperacoes: '',
        idparceiro: null,
        idcultivo: null,
        idpivot: null,
        datalan: new Date(),
        dataoperacao: new Date(),
        obs: '',
      });
      setRows([]);
      setvenda(false);
      setStatuss(true);
      setEdit(false);
    }  
  }, [id]);
       
  useEffect(() => {
    const totalSum = rows.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
    formRef.current?.setFieldValue('total', totalSum);
  }, [rows]);

  const handleSave = (dados: IFormData) => {
    if (id !== 'novo') {   
      dados.idparceiro = idparceiro;         
    }
    if(rows.length != 0){    
      const DadosValidados = {...dados, venda: venda};
      formValidationSchema.
        validate(DadosValidados, { abortEarly: false })
        .then((dadosValidados) => {
          setIsLoading(true);
          if (id === 'novo') {
            SaidaService
              .create(dadosValidados, rows)
              .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    navigate('/saidaestoque');
                  } else {
                    navigate(`/saidaestoque/detalhe/${result}`);
                  }
                }
              });
          } else {
            SaidaService
              .updateById(id, dadosValidados, rows, rowsids)
              .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    navigate('/saidaestoque');
                  }   
                }
              });        
          }   
        })  
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};

          errors.inner.forEach(error => {    
            if (!error.path) return;      

            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        });
    }else{
      alert('A saída deve conter no mínimo um ítem.');
    }
   
  };

  const handleDeleteEntrada = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      SaidaService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/saidaestoque');
          }
        });
    }
  };

  const handleImpressao = (id: string) => {
    SaidaService.Impressao(id)
      .then(result => {   
        if (result instanceof Error) {
          alert(result.message);
        } else {    
          PDFSaida(result);
        }
      });
  };  
    
  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {

      const meuObjeto = {iditens: id};  
     
      rowsids.push(meuObjeto); 
      
      const indexToDelete = rows.findIndex((item) => item.iditens === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
        if(updatedList.length != 0){
          setEdit(true);
        }else{   
          setEdit(false);   
        }
      }
    }
  };

  const handleBuscarDados = (id: string) => {
    const foundItem = rows.find((listItem) => listItem.iditens === id);
    if (foundItem) {
      setDadositens(foundItem);
    }
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  type RowsType = IitensSaidaDialog[];
  
  const handleItens = (dados: IitensSaidaDialog) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);
  
    let updatedList: RowsType;
   
    if (index !== -1) {
      updatedList = [...rows];   
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);

    if(updatedList.length != 0){
      setEdit(true);
    }else{
      setEdit(false);
    }
  };

  const handleVenda = (venda: boolean | undefined) => {
    if(venda != undefined){
      setvenda(venda);
    }
  };

  return ( 
    <LayoutBaseDePaginaNfe
      barraDeFerramentas={
        <FerramentasDeDetalheEntradaSaida
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={statuss ? isLoading : true} 
          mostrarBotaoImpressao={statuss ? isLoading : true} 
          disableBotaoSalvar={isLoading}
          disableBotaoSalvarEFechar={isLoading}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/saidaestoque')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmNovo={() => navigate('/saidaestoque/detalhe/novo')}
          mostrarBotaoNovoCarregando={isLoading}
          aoClicarEmApagar={() => handleDeleteEntrada(id)}
          aoClicarEmImpressao={() => handleImpressao(id)}
        />
      }   
    >   
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>      
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" marginTop={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>   
                <Grid container item direction="row" spacing={2}  >
                  <Grid item xs={6} sm={12} md={2.5} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='datalan'
                      label='Data do Lançamento'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={2.5} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='dataoperacao'
                      label='Data da Saída'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
                    <AutoCompleteOperacoes
                      isExternalLoading={statuss ? isLoading : true} 
                      venda={handleVenda}
                      disabled={edit}
                      tipo={1} //saida 
                    />      
                  </Grid>  
                  <Grid item xs={12} sm={2} md={2.5} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='total'
                      label='Total da Saída'
                      disabled={true}
                    />
                  </Grid>    
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <AutoCompleteFazenda
                      isExternalLoading={isLoading}
                      uuidfazenda={handleuuidfazenda} />
                  </Grid>  
                  {(venda === true) && (
                    <Grid item xs={12} sm={12} md={5.5} lg={8} xl={8}>
                      {id === 'novo' ? (
                        <AutoCompleteParceiro
                          isExternalLoading={id === 'novo' ? isLoading : true}
                        />   
                      ) : (
                        <TextField
                          fullWidth
                          size='small'
                          value={nome}
                          label='Parceiro'   
                          disabled={true}
                        />
                      )}                        
                    </Grid> 
                  )}
                  {(venda === false) && (
                    <>                   
                      <Grid item xs={12} sm={12} md={3} lg={6} xl={6}>
                        <AutoCompleteCultivo
                          isExternalLoading={isLoading} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2.5} lg={2} xl={2}>
                        <AutoCompletePivot
                          isExternalLoading={isLoading}
                          idfazenda={idfazenda} />
                      </Grid>
                    </>    
                  )}        
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{width: 'auto', height:350, maxHeight:350 }}>
                  <Table size="small">
                    <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                      <TableRow >
                        <TableCell style={{ width: 20, color: '#fff' }} align='center'>Quantidade</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>Descrição</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>UN</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>Local</TableCell>
                        <TableCell style={{ color: '#fff' }} align='right'>Valor</TableCell>
                        <TableCell style={{ color: '#fff' }} align='right'>Total</TableCell>
                        <TableCell style={{  color: '#fff' }} align='center'>
                          <IconButton color='inherit' disabled={isLoading}  aria-label="add to shopping cart" size="large">
                            <AddShoppingCartIcon  onClick={() => {setIditens('novo'); if((venda === null || venda === undefined)){alert('Informe a Operação');}else{setOpen(true);}}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead> 
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.iditens} 
                          hover
                          sx={{ cursor: 'pointer',  '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell style={{ width: 20 }} align='right' >{row.qtdsaida.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits:2})}</TableCell>
                          <TableCell align='left' >{row.descricao}</TableCell>
                          <TableCell align='left'>{row.un}</TableCell>
                          <TableCell align='left'>{row.localizacao}</TableCell>
                          <TableCell align='right' >{row.preco.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell align='right' >{row.total.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell align='center'>
                            <IconButton size="small" onClick={() => {setIditens(row.iditens); handleBuscarDados(row.iditens);  setOpen(true);}}>
                              <Icon >edit</Icon>
                            </IconButton>
                            <IconButton disabled={isLoading}  size="small" onClick={() => {handleDelete(row.iditens);}}>
                              <Icon>delete</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={2} >
                  <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='obs'
                      label='Observações'
                      multiline
                      rows={3}
                      disabled={statuss ? isLoading : true}
                    />
                  </Grid>         
                </Grid>
              </Grid>
            </Grid>
            <DialogSaida 
              id={iditens} 
              value={guuid} 
              dados={dadositens}
              open={open} 
              onDados={handleItens} 
              onClose={handleClose} 
              status={true}
              venda={venda!}
            /> 
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePaginaNfe>
  );
};