/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
export interface IVerbasFolha {
  idverbo: string;
  idfolha: string;
  idverbas: string;
  descricao: string;
  qtd: number;
  vencimentos: number;
  descontos: number;
  id: number;
}

export interface IGetFolha {
	nome: string;
	cpf: string;
	funcao: string;
	urlimg: string;
  idfolha: string;
	idfun: string;
	periodo: string;
  produtor: string;
  numero: string;
  ctps: string;
  admissao: Date;
	datageracao: Date;
	salario: number;
	carteira: number;
	vencimentos: number;
	descontos: number;
	total: number;
	status: boolean;
	dv: string;
	tipoconta: string;
	agencia: string;
	conta: string;
	tipopagamento: string;
  itens: IVerbasFolha[];
}  

function recibo(dados: IGetFolha){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const verbas = dados.itens.map((row) => {
    return [
      {text: row.descricao, alignment: 'left',  border: [false, false, false, false], fontSize: 10},
    ]; 
  });  

  const referencia = dados.itens.map((row) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.qtd), alignment: 'center',  border: [false, false, false, false], fontSize: 10},
    ]; 
  });  

  const vencimento = dados.itens.map((row) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.vencimentos), alignment: 'right',  border: [false, false, false, false], fontSize: 10},
    ]; 
  });  

  const desconto = dados.itens.map((row) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.descontos), alignment: 'right', border: [false, false, false, false], fontSize: 10},
    ]; 
  });  

  const documentDefinition : any = {
    pageSize: 'A4',   
    pageMargins: [20, 10, 20, 10],
    content: ([        
      {
        table:{  
          widths: [550],
          body: [
            [{text:  dados.produtor, alignment: 'center', bold: true,  fontSize: 18, border: [true, true, true, false]}],
            [{text: dados.numero, alignment: 'center',  bold: true, fontSize: 14, border: [true, false, true, false]}], 
            [{text: 'Demostativo de Pagamento', alignment: 'center',  fontSize: 14, border: [true, false, true, false]}] 
          ]
        },
      },  
      {
        table:{     
          widths: [268, 132, 132],
          body: [  
            [   
              {text: 'Funcionario: '+dados.nome, alignment: 'left', colSpan: 2,  fontSize: 10},
              {},
              {text: 'Periodo '+dados.periodo, alignment: 'left',  fontSize: 10}
            ],
            [
              {text: 'Cargo: '+dados.funcao, alignment: 'left', colSpan: 2,  fontSize: 10},
              {},
              {text: 'CTPS: '+dados.ctps, alignment: 'left',  fontSize: 10},
            ], 
            [
              {text: 'Depto', alignment: 'left',  fontSize: 10},
              {text: 'Admissão: '+moment(dados.admissao).format('DD/MM/YYYY'), alignment: 'left',  fontSize: 10},
              {text: 'CPF: '+dados.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'), alignment: 'left',  fontSize: 10},
            ] 
          ]
          
        },  
      },
      {
        table:{     
          // heights:[200, 0, 200],
          widths: [186, 73, 132, 132],
          body: [  
            [               
              {text: 'Verbas', margin: [5, 0, 0, 0], bold: true,  border: [true, false, true, true], alignment: 'left', fillColor: '#C0C0C0', fontSize: 12 },
              {text: 'Referência', alignment: 'center', bold: true, border: [true, false, true, true], fillColor: '#C0C0C0', fontSize: 12},
              {text: 'Vencimento', alignment: 'center', bold: true, border: [true, false, true, true], fillColor: '#C0C0C0', fontSize: 12},
              {text: 'Descontos', alignment: 'center', bold: true, border: [true, false, true, true], fillColor: '#C0C0C0', fontSize: 12},
            ],

          ]
        },
      },
      {
        table:{     
          heights:[170, 0, 200],
          widths: [186, 73, 132, 132],
          body: [  
            [
              { 
                style: 'tableExample',
                table: {
                  body: [
                    ...verbas
                  ]
                }
              },
              { 
                margin: [20, 0, 0, 0],
                style: 'tableExample',
                table: {
                  body: [
                    ...referencia
                  ]
                }
              },
              { 
                margin: [80, 0, 0, 0],
                style: 'tableExample',
                table: {
                  body: [
                    ...vencimento
                  ]
                }
              },
              { 
                margin: [80, 0, 0, 0],
                style: 'tableExample',
                table: {
                  body: [  
                    ...desconto
                  ]
                }
              },
            ]

          ]
        },
      },
      {
        table:{     
          widths: [268, 132, 132],
          body: [  
            [               
              {text: '', border: [true, false, true, true],},
              {text: 'Total: '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.vencimentos), alignment: 'right', border: [true, false, true, true],},
              {text: 'Total:  '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.descontos), alignment: 'right', border: [true, false, true, true],},
            ],
            [               
              {text: '', border: [true, false, true, true],},
              {text: 'Total Liquido:', border: [true, false, false, true]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.total), alignment: 'right', border: [false, false, true, true],},
            ],

          ]
        },
      },
      {
        table:{   
          heights:[20, 0, 0],  
          widths: [200, 341],
          body: [  
            [               
              {text: 'Recebi o valor liquido, acima descrito em ___/___/____', border: [true, false, false, true], fontSize: 8, margin: [5, 5, 0, 0] },
              {text: 'Assinatura____________________________________________________________________', border: [false, false, true, true], fontSize: 8, margin: [5, 5, 0, 0] },
            ],
          ]
        },  
      },
        
    ]),
      
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default recibo;


