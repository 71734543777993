import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';

interface NavTabsProps {
  id: string;
  case: number;
}

export default function NavTabs({ id, case: initialCase = 0 }: NavTabsProps) {

  const [value, setValue] = React.useState(initialCase);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    // Aqui você pode adicionar lógica para redirecionar com base no valor da guia
    switch (newValue) {
    case 0:
      navigate(`/funcionarios/detalhe/${id}`);
      break;
    case 1:
      navigate(`/dependentes?idfun=${id}`);
      break;
    case 2:
      navigate(`/verbaseventos?idfun=${id}`);
      break;
    case 3:
      navigate(`/pontoanual?idfun=${id}`);
      break;
    case 4:
      navigate(`/valeresumo?idfun=${id}`);
      break;
      // Add more cases for other tabs based on your requirements
    default:
      break;
    }
  };
  
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Colaborador" />
        <Tab label="Dependentes" />
        <Tab label="Eventos" />
        <Tab label="Ponto Anual" />
        <Tab label="Adiantamento" />
      </Tabs>
    </Box>
  );
}
