/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
 
export interface IDecimo {
  idsalario: string;
  idfun: string;
  nome: string;
  cpf: string;
  admissao: Date;
  scarteira: number;
  salario: number;
  avos: number;
  valorbruto: number;
  inss: number;
  desconto: number;
  valorliquido: number;
}


async function PrintDecimo(dados: IDecimo[]){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const Ttotal = dados.reduce((acc, item) => acc + item.valorliquido, 0);
  const TDesconto = dados.reduce((acc, item) => acc + item.desconto, 0);
  const Tbruto = dados.reduce((acc, item) => acc + item.valorbruto, 0);
  const Tinss = dados.reduce((acc, item) => acc + item.inss, 0);
  
  const rows = dados.map((row) => {  
    return [
      {text: row.nome, alignment: 'left',  fontSize: 8},
      {text: row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'), alignment: 'left',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.scarteira), alignment: 'right', fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.salario), alignment: 'right', fontSize: 8},
      {text: moment(row.admissao).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},
      {text: row.avos, alignment: 'center',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.valorbruto), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.desconto), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.inss), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.valorliquido), alignment: 'right',  fontSize: 8},
    ]; 
  }); 

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

   
  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [10, 10, 10, 10],
    header: 'simple text', 
    footer: {
      columns: [
        'Left part',
        { text: 'Right part', alignment: 'right' }
      ]
    },
    content: ([
      {   
        table: {
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],  
                table: {
                  widths: [500],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: '#FFFFFF',  alignment: 'center', fillColor: '#00A47B', border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                  ]
                }
              },
              
            ],
            [
              {text: 'Relatório de Decimo Terceiro Salario ', colSpan: 2, margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
              {}
            ]
            
          ]
        }
      },
      {
        table:{
          widths: [ 150, 60, 35, 35, 45, 20, 40, 35, 30, 35],
          headerRows: 1,
          body: [
            [
              {text: 'Colaborador', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'CPF', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'salarios', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'carteira', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Admissão', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Avos', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Valor Bruto', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Desconto', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'INSS', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'liquido', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
            ],
            ...rows,
          ]     
        },
      },
      {text: 'Valores Totais', style: 'subheader', fillColor: '#dddddd',  margin: [8, 3, 10, 2]},
      {  
        table:{
         
          widths: [ 150, 60, 35, 35, 45, 20, 40, 35, 30, 35],
          body: [
            [
              {},  
              {},
              {},
              {},
              {},
              {},
              {text: 'Valor Bruto \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tbruto), alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'Desconto \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TDesconto), alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'Inss \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tinss), alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'total \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Ttotal), alignment: 'center', fillColor: '#dddddd', fontSize: 7},
            ],
          ] 
        },
      },
    ]),
      
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default PrintDecimo;


