import { useEffect, useMemo, useState } from 'react';
import { Icon, IconButton, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { FerramentasDaListagemDecimo } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { DecimoService, IDecimo } from '../../../shared/services/api/rh/DecimoTerceiro/DecimoService';
import dayjs from 'dayjs';
import PrintDecimo from './PrintDecimoTerceiro';
import { DialogDecimo } from './DialogoDecimo';

export const ListagemDecimo: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
   
  const [rows, setRows] = useState<IDecimo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [id, setId ] = useState<string>('');
  const [avos, setAvos] = useState(0);

  const anoAtual: number = new Date().getFullYear();

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

 
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const ano = useMemo(() => {
    return Number(searchParams.get('ano') || anoAtual);
  }, [searchParams]);
     
  
  useEffect(() => {
    setIsLoading(true);
     
    debounce(() => {
      DecimoService.GetAll(pagina, 'todos', ano, busca)
        .then((result) => {
          setIsLoading(false);      
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);   
            setRows(result.data);
          }    
        });   
    });     
  }, [busca, ano, pagina, close]);

  const handlePrint = () => {   
    DecimoService.GetAll(1, 'print', ano, '')
      .then((result) => {
        setIsLoading(false);
  
        if (result instanceof Error) {
          alert(result.message);
        } else {
          PrintDecimo(result.data);      
        }
      });
  };  

  const handleClose = () => {
    setClose(true);
    setOpen(false);
  };
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Decimo Terceiro'
      barraDeFerramentas={
        <FerramentasDaListagemDecimo
          textoAno={ano}
          mostrarInputBusca
          textoDaBusca={busca}
          textoBotaoNovo='Imprimir'
          aoClicarEmPrint={() => handlePrint()}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, ano: ano.toString(), pagina: '1' }, { replace: true })}
          aoMudarTextoDeAno={ano => setSearchParams({ ano: ano.toString(),  busca: '',  pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell align='right'>Carteira</TableCell>
              <TableCell align='right'>Salario</TableCell>
              <TableCell align='center'>Admissão</TableCell>
              <TableCell align='center'>Avos</TableCell>
              <TableCell align='right'>Valor Bruto</TableCell>
              <TableCell align='right'>Desconto</TableCell>
              <TableCell align='right'>INSS</TableCell>
              <TableCell align='right'>Liquido</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idsalario} 
                hover
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{row.nome}</TableCell>
                <TableCell align='left'>{row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</TableCell>
                <TableCell align='right'>{row.scarteira.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell align='right'>{row.salario.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell align='center'>{dayjs(row.admissao).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='center'>{row.avos}</TableCell>
                <TableCell align='right'>{row.valorbruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell align='right'>{row.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell align='right'>{row.inss.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell align='right'>{row.valorliquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell>
                  <IconButton size='small'  onClick={() => {setId(row.idsalario); setAvos(row.avos); setOpen(true);}}>
                    <Icon >edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>        
                <TableCell colSpan={10}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>   
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHASSMALL) && (
              <TableRow>
                <TableCell colSpan={10}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHASSMALL)}
                    onChange={(_, newPage) => setSearchParams({ano: ano.toString(), busca: busca, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <DialogDecimo
        id={id}
        value={''}  
        open={open} 
        avos={avos}
        onClose={handleClose}
      />       
    </LayoutBaseDePagina>
  );
};