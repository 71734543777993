import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

import { v4 as uuidv4 } from 'uuid';

export interface IListagemCei {
    idcei: string;
    numero: string;
    caepf: string;
    nome: string;
    status: boolean;
}

export interface IDetalheCei {
  numero: string;
  idprodutor: number;
  endereco: string;
  cidade: string;
  bairro: string;
  cep: string;
  caepf: string;
  status: boolean;
  uf: string;
}

export interface ICeiList{
  idcei: string;
  nome: string;
}

type TCeiComTotalCount = {
    data: IListagemCei[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TCeiComTotalCount | Error> => {
  try {
    
    const urlRelativa = `/cei?page=${page}&limit=${Environment.LIMITE_DE_LINHAS1}&filter=${filter}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS1),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllList = async (filter = '', id = ''): Promise<TCeiComTotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/ceilist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/ceilist/${id}`;
  
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS), 
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheCei | Error> => {
  try {
    const { data } = await Api.get(`/cei/${id}`);

    if (data) {  
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheCei): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Api.post<IDetalheCei>('/cei',  {idcei: guuid, ...dados});
    
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheCei): Promise<void | Error> => {
  try {
    await Api.put(`/cei/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/cei/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const CeiService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  getAllList,
};