import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { EntradaService, IListagemEntradas } from '../../../shared/services/api/estoque/entrada/EntradaService';
import dayjs from 'dayjs';
import { FerramentasDaListagemEntrada } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemEntrada';

export const ListagemDeEntradas: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemEntradas[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  const dataInicial = useMemo(() => {
    return searchParams.get('datainicial') || dayjs().startOf('month').format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const dataFinal = useMemo(() => {
    return searchParams.get('datafinal') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'data'; 
  }, [searchParams]);

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const idparceiro = useMemo(() => {
    return searchParams.get('parceiro') || ''; 
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(data);
  }

  const [busca, setBusca] = useState(true); 
  const [autoComplete, setAutoComplete] = useState(true);
  const [data, setData] = useState(true);

  useEffect(() => {
    if(tipo === 'data'){
      setData(true);
      setBusca(false);
      setAutoComplete(false);
      setRows([]);
      setSearchParams({tipo: 'data', pagina: '1'}, { replace: true });
      setTotalCount(0);
    }
    else if(tipo === 'parceiro'){
      setAutoComplete(true);
      setBusca(false);
      setData(false);
      setRows([]);
      setSearchParams({tipo: 'parceiro', pagina: '1'}, { replace: true });
      setTotalCount(0);
    }else{
      setBusca(true);
      setAutoComplete(false);
      setData(false);
      setRows([]);
      setSearchParams({tipo: 'numero', pagina: '1'}, { replace: true });
      setTotalCount(0);
    }
  }, [tipo]);
  
  useEffect(() => {
    if (tipo === 'numero' && filter != '') { 
      setIsLoading(true);
   
      debounce(() => {
        EntradaService.getAll(pagina, dataInicial, dataFinal, filter, tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      }); 
    } 
    
  }, [filter, pagina]);

  useEffect(() => {
    if (tipo === 'data' && verificaFormato(dataInicial) && verificaFormato(dataFinal)) { 
      setIsLoading(true);
      debounce(() => {
        EntradaService.getAll(pagina, dataInicial, dataFinal, idparceiro, tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setRows([]);
    }        
  }, [dataInicial, dataFinal, pagina]);

  useEffect(() => {
    if (tipo === 'parceiro' && idparceiro != '') { 
      setIsLoading(true);
      debounce(() => {
        EntradaService.getAll(pagina, dataInicial, dataFinal, idparceiro, tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setRows([]);
    }    
  }, [idparceiro, pagina]);
    
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Entradas'
      barraDeFerramentas={
        <FerramentasDaListagemEntrada
          mostrarInputBusca={busca}
          mostrarInputBasicSelect
          mostrarInputData={data}
          mostrarAutoComplete={autoComplete}
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDaBusca={filter}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/entradaestoque/detalhe/novo')}
          aoMudarDataInicial={datainicial => setSearchParams({ tipo: tipo, pagina: '1', datainicial: datainicial, datafinal: dataFinal }, { replace: true })}
          aoMudarDataFinal={datafinal => setSearchParams({ tipo: tipo, pagina: '1', datainicial: dataInicial, datafinal: datafinal }, { replace: true })}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, tipo: tipo, pagina: '1', datainicial: dataInicial, datafinal: dataFinal }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ filter: filter, tipo: textoselect, pagina: '1', datainicial: dataInicial, datafinal: dataFinal  }, { replace: true})}
          aoMudarTextoDoParceiro={(texto, texto1) => setSearchParams({ parceiroselect: texto1, parceiro: texto, filter: filter, tipo: tipo, pagina: '1', datainicial: dataInicial, datafinal: dataFinal }, { replace: true })}
          dadosSelecao={[
            { nameBD: 'data', descricao: 'Data' },
            { nameBD: 'parceiro', descricao: 'Parceiro' },
            { nameBD: 'numero', descricao: 'Nº da Nota' },
          ]}
        />
      } 
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Parceiro</TableCell>
                <TableCell>CPF/CNPJ</TableCell>
                <TableCell>Nº da Nota</TableCell>
                <TableCell>Operação</TableCell>
                <TableCell align='right'>Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.identrada} 
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/entradaestoque/detalhe/${row.identrada}`)}
                >
                  <TableCell>{dayjs(row.dataemissao).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{row.parceiros}</TableCell>
                  <TableCell align='left'>
                    { row.cpf.length == 11 ? (
                      row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                    ) : (
                      row.cpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                    )}                                    
                  </TableCell>
                  <TableCell>{row.numerodoc}</TableCell>
                  <TableCell>{row.descricao}</TableCell>
                  <TableCell align='right' >{row.totalnota.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > 17) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / 17)}
                      onChange={(_, newPage) => setSearchParams({parceiro: idparceiro, filter: filter, tipo: tipo, datainicial: dataInicial, datafinal: dataFinal, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )} 
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};