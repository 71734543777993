import { Box, Button, Icon, Paper, TextField, Typography, useTheme } from '@mui/material';
import { Environment } from '../../environment';

interface IFerramentasDaListagemProps {
  textoTitulo?: string; 
  textoAno: number; 
  mostrarTextoTitulo?: boolean;
  textoDaBusca?: string;
  mostrarInputBusca?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoMudarTextoDeAno?: (novoTexto: number) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmPrint?: () => void;
}
export const FerramentasDaListagemDecimo: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  textoAno = '',
  textoTitulo = '',
  mostrarTextoTitulo = false,
  aoMudarTextoDeBusca,
  aoMudarTextoDeAno,
  mostrarInputBusca = false,
  aoClicarEmPrint,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
}) => {
  const theme = useTheme();

  return (
    <Box
      gap={1}  
      marginX={3}
      padding={1}
      paddingX={1}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
    >
      <TextField
        size="small"
        value={textoAno}
        type='number'
        onChange={(e) => aoMudarTextoDeAno?.(Number(e.target.value))}
      />

      {mostrarInputBusca && (
        <TextField
          size="small"
          value={textoDaBusca}
          placeholder={Environment.INPUT_DE_BUSCA}
          onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
        />
      )}
      
      {mostrarTextoTitulo && (
        <Typography
          component="span"
          sx={{
            fontFamily: 'Arial, sans-serif',
          }}
        >
          {textoTitulo}
        </Typography>
      )}

      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='outlined'
            onClick={aoClicarEmPrint}
            endIcon={<Icon>printoutlined</Icon>}
          >{textoBotaoNovo}</Button>
        )}
      </Box>
    </Box>
  );
};