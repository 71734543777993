/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle,  Grid,   LinearProgress,   Paper, TextField} from '@mui/material';
import { useState } from 'react';
import { BasicSelect, IVFormErrors, VForm, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { DecimoService } from '../../../shared/services/api/rh/DecimoTerceiro/DecimoService';
import ImprTipoPagamentoDecimo from './ImprTipoPagamento';

export interface IFomeTipoPgtoDecimo {
  ano: number;
  banco: string;
  tipopagamento: string;
  tipoconta: string;
  idfazenda: string;
}

const formValidationSchema: yup.ObjectSchema<IFomeTipoPgtoDecimo> = yup.object().shape({
  tipopagamento: yup.string().required(),
  idfazenda: yup.string().optional().default(''),
  ano: yup.number().required(),
  banco: yup.string().optional().default('').when('tipopagamento', {is: 'deposito', then: () => yup.string().required('Campo obrigatório') }),
  tipoconta: yup.string().optional().default('').when('tipopagamento', {is: 'deposito', then: () => yup.string().required('Campo obrigatório') }),
});
          
export const TipoPgamentoDecimo: React.FC = () => {        
  const anoAtual: number = new Date().getFullYear();  
  const navigate = useNavigate(); 
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);  
  const [ano, setAno] = useState(anoAtual);   
  
              
  const handleSave = (dados: IFomeTipoPgtoDecimo) => {  
    dados.idfazenda= '';   
    dados.ano= ano;                    
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        if(dados.tipopagamento === 'cheque'){
          navigate(`/listagemfolhachequedecimo/${dados.ano}`);
        }else{
          setIsLoading(true);
          DecimoService   
            .TipoPagamento(dadosValidados)   
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else 
              {
                ImprTipoPagamentoDecimo(result,  dadosValidados.banco);
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};   
        errors.inner.forEach(error => {   
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);  
      });            
  };                 
     
  return (   
    <VForm ref={formRef} onSubmit={handleSave}>
      <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
        <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' alignContent='center'></Box>
        <Box   width='auto' alignItems="center"  component={Paper} height="auto"  
          alignContent='center'>       
          <DialogTitle>Tipo de Pagamento Decimo Terceiro</DialogTitle>
          <DialogContent  dividers>    
            {isLoading && (                       
              <Grid item>     
                <LinearProgress variant='indeterminate' />      
              </Grid>                      
            )}   
            <Grid container  padding={1} item direction="row" gap={1}>
              <Grid container spacing={1} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <BasicSelect
                    data={[
                      { nameBD: 'cheque', descricao: 'Cheque' },
                      { nameBD: 'deposito', descricao: 'Depósito' },
                      { nameBD: 'dinheiro', descricao: 'Dinheiro' },
                    ]}
                    label='Tipo Pagamento'
                    name='tipopagamento'
                    disabled={isLoading}
                  />  
                </Grid> 
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={ano}
                    type='number'
                    disabled={isLoading}    
                    onChange={(e) =>  setAno(Number(e.target.value))}
                  />
                </Grid> 
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <BasicSelect
                    data={[
                      { nameBD: 'brasil', descricao: 'Brasil' },
                      { nameBD: 'caixa economica', descricao: 'Caixa Econômica' },
                      { nameBD: 'bradesco', descricao: 'Bradesco' },
                    ]}
                    label='Banco'
                    name='banco'   
                    disabled={isLoading}   
                  />   
                </Grid>    
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <BasicSelect
                    data={[
                      { nameBD: 'corrente', descricao: 'Corrente' },
                      { nameBD: 'poupanca', descricao: 'Poupança' },
                    ]}
                    label='Tipo Conta'
                    name='tipoconta'
                    disabled={isLoading}
                  />
                </Grid> 
              </Grid>        
            </Grid>         
          </DialogContent>     
          <DialogActions>
            <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Visualizar</Button>
          </DialogActions>
        </Box>
      </Box>
    </VForm> 
  );
};   