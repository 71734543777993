import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagemCadastros } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemPatrimonios, PatrimoniosService } from '../../../shared/services/api/cadastros/PatrimoniosService';

export const ListagemDePatrimonios: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemPatrimonios[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'p.descricao'; 
  }, [searchParams]);

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      PatrimoniosService.getAll(pagina, tipo, filter)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [pagina, filter]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Patrimônios'
      barraDeFerramentas={
        <FerramentasDaListagemCadastros
          mostrarInputBusca
          mostrarInputBasicSelect
          textoDaBusca={filter}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/patrimonios/detalhe/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ filter: filter, tipo: textoselect, pagina: '1' }, { replace: true})}
          dadosSelecao={[
            { nameBD: 'p.descricao', descricao: 'Descrição' },
            { nameBD: 'g.descricao', descricao: 'Grupo' },
            { nameBD: 'f.descricao', descricao: 'Localização' },
            { nameBD: 'p.numero', descricao: 'Número' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Grupo</TableCell>
              <TableCell>Localização</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idpatrimonio} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/patrimonios/detalhe/${row.idpatrimonio}`)}
              >
                <TableCell>{row.numero}</TableCell>
                <TableCell>{row.descricao}</TableCell>
                <TableCell>{row.grupo}</TableCell>
                <TableCell>{row.localizacao}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && ( 
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_CADASTRO) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_CADASTRO)}
                    onChange={(_, newPage) => setSearchParams({ filter,  tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};