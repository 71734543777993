/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress,  Paper, TextField } from '@mui/material';
import { useState } from 'react';
import {  AutoCompleteFazenda,  AutoCompletePeriodo, BasicSelect, IVFormErrors, VForm, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { DecimoService } from '../../../shared/services/api/rh/DecimoTerceiro/DecimoService';
import { IFomeTipoPgtoDecimo } from './TipoPgamentoDecimo';
import recibosDecimo from './Impressaorecibos';

const formValidationSchema: yup.ObjectSchema<IFomeTipoPgtoDecimo> = yup.object().shape({
  tipopagamento: yup.string().required(),
  idfazenda: yup.string().optional().default(''),
  ano: yup.number().required(),
  banco: yup.string().optional().default(''),
  tipoconta: yup.string().optional().default(''),
});
           
export const ReciboDecimoTerceiro: React.FC = () => {      
  const anoAtual: number = new Date().getFullYear();        
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);   
  const [ano, setAno] = useState(anoAtual);            
                          
  const handleSave = (dados: IFomeTipoPgtoDecimo) => {   
    dados.banco= '',
    dados.tipoconta='',        
    dados.ano= ano;       
    console.log(dados.idfazenda);                    
    formValidationSchema.       
      validate(dados, { abortEarly: false })         
      .then((dadosValidados) => {      
        setIsLoading(true);           
        DecimoService      
          .TipoPagamento(dadosValidados)   
          .then((result) => {   
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else 
            { 
              recibosDecimo(result);  
            }  
          });       
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};   
        errors.inner.forEach(error => {   
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });            
  };          
  
  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
        <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
        <Box   width='auto' alignItems="center"  component={Paper} height="auto" margin={1} 
          alignContent='center'>       
          <DialogTitle>Recibo Pagamento Decimo Terceiro</DialogTitle>
          <DialogContent  dividers>    
            {isLoading && (           
              <Grid item>  
                <LinearProgress variant='indeterminate'/>  
              </Grid>                      
            )}   
            <Grid container  padding={1} item direction="row" >
              <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AutoCompleteFazenda 
                    isExternalLoading={isLoading}  />
                </Grid> 
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>              
                  <BasicSelect
                    data={[   
                      { nameBD: 'cheque', descricao: 'Cheque' },                  
                      { nameBD: 'deposito', descricao: 'Depósito' },   
                      { nameBD: 'dinheiro', descricao: 'Dinheiro' },    
                    ]}
                    label='Tipo Pagamento'
                    name='tipopagamento'                                                   
                    disabled={isLoading}                                  
                  />           
                </Grid>                    
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={ano}
                    type='number'
                    disabled={isLoading}    
                    onChange={(e) =>  setAno(Number(e.target.value))}
                  />
                </Grid> 
              </Grid>        
            </Grid>         
          </DialogContent>     
          <DialogActions>
            <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Visualizar</Button>
          </DialogActions>
        </Box>
      </Box>
    </VForm> 
  );
};   