/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface ITipoPgto {
  idsalario: string;
  nome: string;
  cpf: string;
  admissao: Date;
  tipoconta: string;
  agencia: string;
  conta: string;
  produtor: string;
  numero: string;
  valorliquido: number;
  ctps: string;
  funcao: string;
  ano: number;
  valorbruto: number;
  inss: number;
  desconto: number;
  avos: number;
}

function recibosDecimo(rows: ITipoPgto[]){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 10, 10, 10],  
    pageOrientation: 'portrait',
    header: 'simple text',
    content: [
      rows.map(e => {
        return [
          [             
            [                              
              {     
                table:{  
                  widths: [550],
                  body: [
                    [{text: ' ', alignment: 'center', bold: true,  fontSize: 7, border: [true, true, true, false]}],
                    [{text: e.produtor, alignment: 'center', bold: true,  fontSize: 18, border: [true, false, true, false]}],
                    [{text: e.numero, alignment: 'center',  bold: true, fontSize: 14, border: [true, false, true, false]}], 
                    [{text: 'Demostativo de Pagamento', alignment: 'center',  fontSize: 14, border: [true, false, true, false]}] 
                  ]
                },
              },  
              {
                table:{    
                  widths: [268, 132, 132],
                  body: [  
                    [   
                      {text: 'Funcionario: '+e.nome, alignment: 'left', colSpan: 2,  fontSize: 10},
                      {},
                      {text: '13º Salário/'+e.ano, alignment: 'left',  fontSize: 10}
                    ],
                    [
                      {text: 'Cargo: '+e.funcao, alignment: 'left', colSpan: 2,  fontSize: 10},
                      {},
                      {text: 'CTPS: '+e.ctps, alignment: 'left',  fontSize: 8},
                    ], 
                    [
                      {text: 'Depto', alignment: 'left',  fontSize: 10},
                      {text: 'Admissão: '+moment(e.admissao).format('DD/MM/YYYY'), alignment: 'left',  fontSize: 10},
                      {text: 'CPF: '+e.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'), alignment: 'left',  fontSize: 10},
                    ] 
                  ]
                  
                },  
              },
              {
                table:{     
                  widths: [186, 73, 132, 132],
                  body: [  
                    [               
                      {text: 'Verbas', margin: [5, 0, 0, 0], bold: true,  border: [true, false, true, true], alignment: 'left', fillColor: '#C0C0C0', fontSize: 12 },
                      {text: 'Referência', alignment: 'center', bold: true, border: [true, false, true, true], fillColor: '#C0C0C0', fontSize: 12},
                      {text: 'Vencimento', alignment: 'center', bold: true, border: [true, false, true, true], fillColor: '#C0C0C0', fontSize: 12},
                      {text: 'Descontos', alignment: 'center', bold: true, border: [true, false, true, true], fillColor: '#C0C0C0', fontSize: 12},
                    ],
                    [
                      {text: '13 salário', alignment: 'left', bold: true, border: [true, false, false, false],  fontSize: 10},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.avos), alignment: 'center', border: [true, false, false, false], fontSize: 10},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.valorbruto), alignment: 'right', border: [true, false, false, false], fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: 'Desconto INSS 13º salário', alignment: 'left', bold: true, border: [true, false, false, false], fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.inss), alignment: 'right', border: [true, false, true, false], fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, false, true, false],  fontSize: 10},
                    ],                
                    [
                      {text: '', alignment: 'left', bold: true, border: [true, true, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, true, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, true, true, false],  fontSize: 10},
                      {text: '', alignment: 'left', bold: true, border: [true, true, true, false],  fontSize: 10},
                    ],
                  ]
                },
              },
              {
                table:{     
                  widths: [268, 132, 132],
                  body: [  
                    [               
                      {text: '', border: [true, false, true, true],},
                      {text: 'Total: '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.valorbruto), alignment: 'right', border: [true, false, true, true], fontSize: 10},
                      {text: 'Total:  '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.desconto + e.inss), alignment: 'right', border: [true, false, true, true], fontSize: 10},
                    ],
                    [               
                      {text: '', border: [true, false, true, true],},
                      {text: 'Total Liquido:', border: [true, false, false, true]},
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.valorliquido), alignment: 'right', border: [false, false, true, true], fontSize: 10},
                    ],
        
                  ]
                },
              },
              {
                margin: [0, 0, 0, 18],
                table:{   
                  heights:[25, 0, 0],  
                  widths: [200, 341],
                  body: [  
                    [               
                      {text: 'Recebi o valor liquido, acima descrito em ___/___/____', border: [true, false, false, true], fontSize: 8, margin: [5, 5, 0, 0] },
                      {text: 'Assinatura____________________________________________________________________', border: [false, false, true, true], fontSize: 8, margin: [5, 5, 0, 0] },
                    ],
                  ]
                },  
              },     
            ]
          ]                           
            
        ];
      }     
      ),

    ]
  };
  pdfMake.createPdf(documentDefinition).open();

}
export default recibosDecimo;


