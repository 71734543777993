import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, BasicSelect, BasicSelectUF, VTextFieldData, VFieldMoney, VTextFieldCpf, cpfValidator, VTextFieldTelefone, VTextFieldCelular, AutoCompleteCEI, VTextFieldCep, VFieldMoneyHidden, AutoCompleteFazenda, AutoCompleteStatusColaborador} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePaginaColaborador } from '../../../shared/layouts';
import { ColaboradorService } from '../../../shared/services/api/rh/colaborador/ColaboradorService';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import NavTabs from './NavTabs';
import { Environment } from '../../../shared/environment';
import { DialogHistoricoDeSalario } from './DialogHistoricoDeSalario';
import CartaoDePonto from './CartaoDePonto';
import CartaoDePontoVerso from './CartaoDePontoVerso';
import { DialogFerias } from './DialogFerais';
import { DialogFeriasAdd } from './DialogFeraisAdiconar';
import { v4 as uuidv4 } from 'uuid';
import { DialogAuxilioAdiconar } from './DialogAuxilioAdiconar';
import { DialogAuxilio } from './DialogAuxilio';
 

type NullableDate = Date | null | undefined;
  
interface IFormData {
  nome: string;
  apelido: string;
  cpf: string;
  rg: string;
  ctps: string;
  estadocivil: string;
  nascimento: Date; 
  endereco: string;
  bairro: string;
  cidade: string;
  uf: string;
  telefone: string;
  telefone1: string;
  email: string;
  banco: string;
  agencia: string;
  conta: string;
  sexo: string;
  cep:string;
  dv:string;
  tipoconta: string;
  datacadastro: NullableDate; 
  pai: string;
  mae: string;
  conjuge: string;
  cargo: string;
  demissional: NullableDate; 
  admissao: Date; 
  tipopagamento: string;
  status: boolean;
  pis: string;
  funcao: string;
  salario: number;
  scarteira: number;
  idcei?: string | null;
  idfazenda: string;
  urlimg: string;
  obs: string;
  almoco: boolean;
  janta: boolean;
  cafe: boolean;
  sus: string;
  dataexperiencia: Date; 
  meiaalimentacao: boolean;
  insalubridade: boolean;
  percentualinslubridade: number;
  taxanegociavel: boolean;
  idstatus: string;
  presencaautomatica: boolean;
  pisosalarial: number;
  sabado: boolean;
}

const formValidationSchema: yup.ObjectSchema< Omit <IFormData, 'urlimg'>> = yup.object().shape({
  nome: yup.string().required().min(3).max(200),
  apelido: yup.string().optional().default(''),
  cpf: yup.string().required().min(11).max(11).test('cpf', 'CPF inválido', cpfValidator),
  rg: yup.string().optional().default(''),
  ctps: yup.string().optional().default(''),
  estadocivil: yup.string().optional().default(''),
  nascimento: yup.date().required(),
  endereco: yup.string().optional().default(''),
  bairro: yup.string().optional().default(''),
  cidade: yup.string().optional().default(''),
  uf: yup.string().optional().default(''),
  telefone: yup.string().optional().default('').max(10),
  telefone1: yup.string().optional().default('').max(11),
  email: yup.string().email().optional().default(''),
  banco: yup.string().optional().default('').when('tipopagamento', {
    is: 'deposito',
    then: () => yup.string().required('Campo obrigatório') 
  }),
  agencia: yup.string().optional().default('').when('tipopagamento', {
    is: 'deposito',
    then: () => yup.string().required('Campo obrigatório') 
  }),
  conta: yup.string().optional().default('').when('tipopagamento', {
    is: 'deposito',
    then: () => yup.string().required('Campo obrigatório') 
  }),
  sexo: yup.string().optional().default(''),
  cep: yup.string().optional().default(''),
  dv: yup.string().optional().default('').when('tipopagamento', {
    is: 'deposito',
    then: () => yup.string().required('Campo obrigatório') 
  }),
  tipoconta: yup.string().optional().default('').when('tipopagamento', {
    is: 'deposito',
    then: () => yup.string().required('Campo obrigatório') 
  }),
  datacadastro: yup.date().nullable().optional().default(null),
  pai: yup.string().optional().default(''),
  mae: yup.string().optional().default(''),
  conjuge: yup.string().optional().default('').when('estadocivil', {
    is: 'casado',
    then: () => yup.string().required('Campo obrigatório') 
  }),
  cargo: yup.string().optional().default(''),
  demissional: yup.date().nullable().optional().default(null).when('$isStatus', {
    is: true,
    then: () => yup.date().nullable().required('Campo obrigatório') 
  }), 
  admissao: yup.date().required(),
  tipopagamento: yup.string().required('Campo obrigatório').max(40),
  status: yup.boolean().required().default(true), 
  pis: yup.string().optional().default(''),
  funcao: yup.string().optional().default(''),
  salario: yup.number().required().moreThan(1),
  pisosalarial: yup.number().optional().default(0),
  scarteira: yup.number().required().moreThan(1),
  idcei: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idfazenda: yup.string().required().min(30).max(150),
  obs: yup.string().optional().default(''),
  almoco: yup.boolean().required().default(false),
  janta: yup.boolean().required().default(false),
  cafe: yup.boolean().required().default(false),
  sus: yup.string().optional().default(''),
  dataexperiencia: yup.date().required(),
  meiaalimentacao: yup.boolean().required().default(false),
  insalubridade: yup.boolean().required().default(false),
  percentualinslubridade: yup.number().optional().default(0).when('insalubridade', {
    is: true,
    then: () => yup.number().moreThan(0).required('Campo obrigatório')
  }).transform((originalValue, originalObject) => {
    // Se 'insalubridade' for false, limpe o valor de 'percentualinslubridade'
    if (!originalObject.insalubridade) {
      return 0;
    }
    // Caso contrário, mantenha o valor original
    return originalValue;
  }),
  taxanegociavel: yup.boolean().required().default(true),
  idstatus: yup.string().required(),
  presencaautomatica: yup.boolean().required().default(false),
  sabado: yup.boolean().required().default(false),
});

export const DetalheDeColaborador: React.FC = () => {
  const [urlimg, setUrlimg] = useState('');
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [open, setOpen] = useState(false);
  const [openferais, setOpenFerias] = useState(false);
  const [openferaisadd, setOpenFeriasadd] = useState(false);
  const [openauxilio, setOpenAuxilio] = useState(false);
  const [openauxilioadd, setOpenAuxilioadd] = useState(false);
  const [status, setStatus] = useState<boolean>();
  const [nome, setNome] = useState('');

  const handleStatus = (status: boolean | undefined) => {
    if(status != undefined){
      setStatus(status);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };   

  const handleCloseFerias = () => {
    setOpenFerias(false);
  };   

  const handleCloseFeriasadd = () => {
    setOpenFeriasadd(false);
  }; 

  const handleCloseAuxilio = () => {
    setOpenAuxilio(false);
  };   

  const handleCloseAuxilioadd = () => {
    setOpenAuxilioadd(false);
  }; 


  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      ColaboradorService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/funcionarios');
          } else {
            setStatus(result.status);
            setNome(result.nome);    
            if (result.urlimg != null) {
              setUrlimg(result.urlimg);
              setImage(Environment.URL_IMGFUNCIONARIO+result.urlimg);         
            }       
            formRef.current?.setData(result);                                            
          }
        }); 
    } else {
      formRef.current?.setData({               
        nome: '',
        apelido: '',
        cpf: '',
        rg: '',
        ctps: '',
        estadocivil: '',
        nascimento: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
        telefone1: '',
        email: '',
        banco: '',
        agencia: '',
        conta: '',
        sexo: '',
        cep: '',
        dv:'',
        tipoconta: '',
        datacadastro: null,
        pai: '',
        mae: '',
        conjuge: '',
        cargo: '',
        demissional: null,
        admissao: '',
        tipopagamento: '',
        status: true,
        pis: '',
        funcao: '',
        salario: 0.00,
        scarteira: 0.00,
        idcei: '',
        idfazenda: '',
        obs: '',
        almoco: false,
        janta: false,
        cafe: false,
        sus: '',
        codcartao: '',
        dataexperiencia: '',
        meiaalimentacao: false,
        insalubridade: false,
        percentualinslubridade: 0,
        taxanegociavel: true,
        presencaautomatica: false,
        pisosalarial: 0,
        sabado: false,
      });
      setImage(null);
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    dados.status = status!;
    if(status === false){
      dados.demissional = null;
      formRef.current?.setFieldValue('demissional', null);
    }
    const validationContext = { isStatus : status};
    formValidationSchema.
      validate(dados, {context: validationContext , abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          ColaboradorService
            .create(selectedImage, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/funcionarios');
                } else {
                  navigate(`/funcionarios/detalhe/${result}`);
                }
              }
            });
        } else {
          ColaboradorService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/funcionarios');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        console.log(validationErrors);
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ColaboradorService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/funcionarios');
          }
        });
    } 
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files?.[0]);
    const imagem =  event.target.files?.[0];  
    if (imagem) {
      const imageUrl = URL.createObjectURL(imagem);
      setImage(imageUrl);
    }
    if (id !== 'novo') {
      ColaboradorService.updateByIdImg(id, imagem, urlimg)  
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Imgaem alterada com sucesso!');
            navigate('/funcionarios');
          }
        });
    }
  };
  
  return (
    <LayoutBaseDePaginaColaborador
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
          mostrarBotaoHistorico={id !== 'novo'}
          mostrarBotaoferais={id !== 'novo'}
          mostrarBotaoauxilio={id !== 'novo'}
          mostrarBotaoHistoricoCarregando = {isLoading}
          mostrarBotaoferaisCarregando = {isLoading}
          mostrarBotaoauxilioCarregando = {isLoading}
          mostrarBotaoCartaoPonto 
          mostrarBotaoCartaoPontoCarregando = {isLoading}
          aoClicarEmCartaoPonto={() => CartaoDePonto(nome, id, urlimg)}      
          mostrarBotaoCartaoPontoVerso  
          mostrarBotaoCartaoPontoVersoCarregando = {isLoading}
          aoClicarEmCartaoPontoVerso={() => CartaoDePontoVerso(nome, id,)}  
          aoClicarEmSalvar={save}       
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/funcionarios')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/funcionarios/detalhe/novo')}
          aoClicarEmHistorico={() => setOpen(true)}
          aoClicarEmFerias={() => setOpenFerias(true)}
          aoClicarEmFeriasAdd={() => setOpenFeriasadd(true)}
          aoClicarEmAuxilio={() => setOpenAuxilio(true)}
          aoClicarEmAuxilioAdd={() => setOpenAuxilioadd(true)}
          mostrarBotaoNovoCarregando = {isLoading}
        />   
      }      
    > 
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={0.5} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            {(id != 'novo') && (
              <NavTabs
                id={id}             
                case={0}                       
              />
            )}
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={2.5} lg={2} xl={1.5}>  
                <Box width='100%' component={Paper} borderColor='red'>
                  <input
                    type="file"
                    id="avatar-input"  
                    accept="image/*"  
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="avatar-input">
                    <Avatar  
                      variant='rounded'
                      alt='F'
                      src={image || undefined}
                      sx={{
                        width: {xs: 150, sm: 150, md: 150, lg: 150, xl: 150},           
                        height: {xs: 180, sm: 180, md: 183, lg: 183, xl: 183},
                        justifySelf:'center',
                      }}
                    />
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9.5} lg={10} xl={10.5}>
                <Grid container item direction="row" spacing={1}> 
                  <Grid item xs={12} sm={12} md={8.4} lg={8.4} xl={8}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='nome'
                      disabled={isLoading}
                      label='Nome'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.6} lg={3.6} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='apelido'
                      disabled={isLoading}
                      label='Apelido'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={2} xl={2}>
                    <BasicSelect
                      data={[
                        { nameBD: 'masculino', descricao: 'Masculino' },
                        { nameBD: 'feminino', descricao: 'Feminino' },
                      ]}
                      label='Sexo'
                      name='sexo'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={2.4} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='nascimento'
                      disabled={isLoading}
                      label='Data Nascimento'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.4} lg={4} xl={4}>
                    <VTextFieldCpf
                      fullWidth
                      size='small'
                      name='cpf'
                      disabled={isLoading}
                      label='CPF'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3.6} lg={3.6} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='rg'
                      disabled={isLoading}
                      label='RG'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={5} lg={4.4} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='sus'
                      disabled={isLoading}
                      label='Cartão do SUS'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3.4} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='ctps'
                      disabled={isLoading}
                      label='CTPS'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3.6} lg={3.6} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='pis'
                      disabled={isLoading}
                      label='PIS'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8.4} lg={8.4} xl={8}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='endereco'
                      disabled={isLoading}
                      label='Endereço'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.6} lg={3.6} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='bairro'
                      disabled={isLoading}
                      label='Bairro'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1}> 
                  <Grid item xs={12} sm={12} md={2.5} lg={2} xl={1.5}>
                    <VTextFieldCep
                      fullWidth
                      size='small'
                      name='cep'
                      disabled={isLoading}
                      label='CEP'
                    />
                  </Grid>
                  <Grid item xs={9} sm={9} md={4.6} lg={3} xl={3}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='cidade'
                      disabled={isLoading}
                      label='Cidade'
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                    <BasicSelectUF
                      name='uf'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.9} lg={2} xl={2}>
                    <VTextFieldTelefone
                      fullWidth
                      size='small'
                      name='telefone'
                      disabled={isLoading}
                      label='Telefone'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={3} xl={3.5}>
                    <VTextFieldCelular
                      fullWidth
                      size='small'
                      name='telefone1'
                      disabled={isLoading}
                      label='Celular'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4.6} lg={5} xl={4.5}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='email'
                      disabled={isLoading}
                      label='E-mail'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={4}>
                    <BasicSelect
                      data={[
                        { nameBD: 'casado', descricao: 'Casado (a)' },
                        { nameBD: 'solteiro', descricao: 'Solteiro (a)' },
                        { nameBD: 'divorciado', descricao: 'Divorciado (a)' },
                        { nameBD: 'viuvo', descricao: 'Viúvo (a)' },
                      ]}
                      label='Estado Civil'
                      name='estadocivil'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2.9} lg={5} xl={3.5}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='conjuge'
                      disabled={isLoading}
                      label='Cônjuge'
                    />
                  </Grid><Grid item xs={12} sm={5.1} md={4.1} lg={5} xl={4.5}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='pai'
                      disabled={isLoading}
                      label='Pai'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='mae'
                      disabled={isLoading}
                      label='Mãe'
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.9} md={2.9} lg={3} xl={3.5}>
                    <AutoCompleteFazenda
                      isExternalLoading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7.1} lg={5} xl={4.5}>
                    <AutoCompleteCEI isExternalLoading={isLoading} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4.9} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='cargo'
                      disabled={isLoading}
                      label='Cargo'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4.6} lg={3} xl={3.5}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='funcao'
                      disabled={isLoading}
                      label='Função'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={2.5} xl={2.5}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='datacadastro'
                      disabled={true}
                      label='Data Cadastro'
                    />
                  </Grid> 
                  <Grid item xs={6} sm={6} md={2.5} lg={2.5} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='admissao'
                      disabled={isLoading}
                      label='Data Admissão'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.4} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='dataexperiencia'
                      disabled={isLoading}
                      label='Data Experiência'
                    />
                  </Grid>
                  {(id != 'novo' ? 
                    <Grid item xs={6} sm={6} md={2.5} lg={2} xl={2}>
                      <VTextFieldData
                        fullWidth
                        name='demissional'
                        disabled={isLoading}
                        label='Data Demissão'
                      />
                    </Grid>
                    :
                    <Grid item xs={6} sm={6} md={2.5} lg={2} xl={2}>
                      <TextField
                        fullWidth
                        size='small'
                        disabled={true}
                        label='Data Demissão'
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6} md={4.6} lg={3} xl={3.5}>
                    <AutoCompleteStatusColaborador
                      isExternalLoading={isLoading}
                      status={handleStatus}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={2.5} xl={2.5}>
                    <VFieldMoneyHidden
                      fullWidth
                      size='small'
                      name='scarteira'
                      disabled={isLoading}
                      label='Salário Carteira'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.4} lg={2.5} xl={2}>
                    <VFieldMoneyHidden
                      fullWidth
                      size='small'
                      name='salario'
                      disabled={isLoading}
                      label='Salário'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={2} xl={2}>
                    <VFieldMoneyHidden
                      fullWidth
                      size='small'
                      name='pisosalarial'
                      disabled={isLoading}
                      label='Piso Salarial'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={2} xl={2}>
                    <BasicSelect
                      data={[
                        { nameBD: 'cheque', descricao: 'Cheque' },
                        { nameBD: 'deposito', descricao: 'Depósito' },
                        { nameBD: 'dinheiro', descricao: 'Dinheiro' },
                      ]}
                      label='Tipo Pagamento'
                      name='tipopagamento'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.1} lg={3} xl={3.5}>
                    <BasicSelect
                      data={[
                        { nameBD: 'corrente', descricao: 'Corrente' },
                        { nameBD: 'poupanca', descricao: 'Poupança' },
                      ]}
                      label='Tipo Conta'
                      name='tipoconta'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.5} lg={2.5} xl={2.5}>
                    <BasicSelect
                      data={[
                        { nameBD: 'brasil', descricao: 'Brasil' },
                        { nameBD: 'caixa economica', descricao: 'Caixa Econômica' },
                        { nameBD: 'bradesco', descricao: 'Bradesco' },
                      ]}
                      label='Banco'
                      name='banco'   
                      disabled={isLoading}   
                    />   
                  </Grid>
                  <Grid item xs={6} sm={6} md={2.4} lg={2.5} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='agencia'
                      disabled={isLoading}
                      label='Agência'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={3.4} xl={3.4}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='conta'
                      disabled={isLoading}
                      label='Conta'
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={1.1} lg={0.6} xl={0.6}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='dv'
                      disabled={isLoading}
                      label='DV'
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4.9} lg={3} xl={3.5}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='percentualinslubridade'
                      disabled={isLoading}
                      label='Percentual de Insalubridade'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2.5} lg={1.8} xl={1.3}>
                    <VSwitch
                      name='insalubridade'
                      label='Insalubridade'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={1.5} lg={1} xl={1}>
                    <VSwitch 
                      name='cafe'
                      label='Café'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={1.8} lg={1.3} xl={1}>
                    <VSwitch
                      name='almoco'
                      label='Almoço'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={1.8} lg={1.3} xl={1}>
                    <VSwitch
                      name='janta'
                      label='Janta'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={3} lg={1.6} xl={1.5}>
                    <VSwitch
                      name='meiaalimentacao'
                      label='Meia Alime.'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={2.9} lg={2} xl={1.5}>
                    <VSwitch
                      name='taxanegociavel'
                      label='Taxa Negociavel'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid>  
                  <Grid item xs={12} sm={12} md={3.5} lg={3} xl={2}>
                    <VSwitch 
                      name='presencaautomatica'
                      label='Presença Automática'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid>        
                  <Grid item xs={12} sm={12} md={3.5} lg={3} xl={2}>
                    <VSwitch 
                      name='sabado'
                      label='Presença Automática Sabado'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid>                
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='obs'
                      disabled={isLoading}
                      label='Observações'
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 
      <DialogHistoricoDeSalario
        id={id}
        value={''}  
        open={open} 
        onClose={handleClose}
      /> 
      <DialogFerias
        id={id}
        value={''}  
        open={openferais} 
        onClose={handleCloseFerias}
      /> 
      <DialogFeriasAdd
        id={uuidv4()}
        idfun={id}
        value={''}  
        open={openferaisadd} 
        onClose={handleCloseFeriasadd}
      /> 
      <DialogAuxilioAdiconar
        id={uuidv4()}
        idfun={id}
        value={''}  
        open={openauxilioadd} 
        onClose={handleCloseAuxilioadd}
      /> 
      <DialogAuxilio
        id={id}
        value={''}  
        open={openauxilio} 
        onClose={handleCloseAuxilio}
      /> 
    </LayoutBaseDePaginaColaborador>
  );
};