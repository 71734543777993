import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid,  LinearProgress,   Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { DecimoService } from '../../../shared/services/api/rh/DecimoTerceiro/DecimoService';
import DeleteIcon from '@mui/icons-material/Delete';
           
export const GeraDecimo: React.FC = () => {                      
  const [isLoading, setIsLoading] = useState(false); 
  const [ano, setAno] = useState('');
                      
  const handleGera = () => {            
    setIsLoading(true);                         
    DecimoService                 
      .create(ano)      
      .then((result) => {           
        setIsLoading(false);              
        if (result instanceof Error) {                
          alert(result.message);                     
        }else {   
          alert('Decimo Gerada Com Sucesso!');  
        }             
      });              
  };   

  const handleExcluir = () => {    
    if (confirm('Realmente deseja Excluir a Decimo Terceiro?')) {
      setIsLoading(true);                         
      DecimoService                 
        .deleteAno(ano)   
        .then((result) => {           
          setIsLoading(false);              
          if (result instanceof Error) {                  
            alert(result.message);                  
          }else {   
            alert('Excluida Com Sucesso!');  
          }             
        });         
    }             
  };                                                           
  return (
    <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='auto' height="80%" margin={1}  alignContent='center'></Box>
      <Box   width='auto' alignItems="center"  component={Paper} height="auto" margin={1} 
        alignContent='center'>       
        <DialogTitle>Gera Decimo Terceiro</DialogTitle>
        <DialogContent  dividers>    
          {isLoading && (             
            <Grid item>        
              <LinearProgress variant='indeterminate' />       
            </Grid>                              
          )}   
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  size='small'
                  value={ano}
                  type='number'
                  disabled={isLoading}
                  label='Ano'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAno(event.target.value);
                  }}
                />
              </Grid> 
            </Grid> 
          </Grid>         
        </DialogContent>     
        <DialogActions>
          <Box flex={1} display="flex" justifyContent="start">
            <Button fullWidth variant='contained' color='error'  startIcon={<DeleteIcon />} onClick={handleExcluir}>Excluir</Button>
          </Box>
          <Button fullWidth variant='contained' autoFocus onClick={handleGera}>Gera</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   