/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,  TextField } from '@mui/material';
import { VForm,  useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { DecimoService } from '../../../shared/services/api/rh/DecimoTerceiro/DecimoService';

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  avos: number;
  open: boolean;
  onClose: (value?: string) => void;
}

const ValidationSchema = yup.object().shape({
  avos: yup.number().required().moreThan(1).max(12),
});

export const DialogDecimo: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [avos, setAvos] = useState(1);
  const [avosError, setAvosError] = useState('');

  const handleSave = () => {
    setIsLoading(true);
    ValidationSchema
      .validate({ avos }, { abortEarly: false })
      .then(dadosValidados => {
        DecimoService.updateById(props.id,  dadosValidados.avos)
          .then(() => {
            setIsLoading(false);
            onClose(valueProp);
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'avos') {
            setAvosError(error.message);
          }
        });
      });
  };

  useEffect(() => {
    setAvos(props.avos);
  }, [props.id]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Editar Decimo Terceiro</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column"  >
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >                
                  <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                    <TextField
                      size="small"
                      value={avos}
                      type='number'
                      disabled={isLoading}    
                      error={!!avosError}
                      helperText={avosError}
                      onKeyDown={() => setAvosError('')}
                      onChange={(e) =>  setAvos(Number(e.target.value))}
                    />
                  </Grid>                        
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}; 