import { ApiFinanceira } from '../axiosfinanceiro';

interface ContasPagar {
  dataemissao: Date;
  vencimento: Date;
  nome: string;
  cpf: string;
  historico: string;
  fazenda: string;
  numerodoc: string;
  valorparcela: number;
  acrescimo: number;
  valorpago: number;
  desconto: number;
  valorpagar: number;
}

interface IParceirosPagar {
  idparceiro: string;
  nome: string;
  cpf: string;
  valorparcela: number; 
  acrescimo: number;
  valorpago: number;
  desconto: number; 
  valorpagar: number;
}

interface IVencPagar {
  vencimento: Date;
  valorparcela: number; 
  acrescimo: number;   
  valorpago: number;
  desconto: number; 
  valorpagar: number;
}


interface EntryPagar {
  contas: IParceirosPagar;
  contaspagar: ContasPagar[];
}

interface EntryPagarVenc {
  contas: IVencPagar;
  contaspagar: ContasPagar[];
}

const RelatorioContasPagar = async (parceiro: string, fazenda: string, planoconta: string, datainicial: string, datafinal: string): Promise<ContasPagar[] | Error> => {
  try {
    const urlRelativa = `/relatoriocontaspagar?fazenda=${fazenda}&parceiro=${parceiro}&planoconta=${planoconta}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioContasPagarAgrupadoParceiro = async (parceiro: string, fazenda: string, agrupar: string, planoconta: string, datainicial: string, datafinal: string): Promise<EntryPagar[] | Error> => {
  try {
    const urlRelativa = `/relatoriocontaspagar?fazenda=${fazenda}&parceiro=${parceiro}&agrupar=${agrupar}&planoconta=${planoconta}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioContasPagarAgrupadoVencimento = async (parceiro: string, fazenda: string, agrupar: string, planoconta: string, datainicial: string, datafinal: string): Promise<EntryPagarVenc[] | Error> => {
  try {
    const urlRelativa = `/relatoriocontaspagar?fazenda=${fazenda}&parceiro=${parceiro}&agrupar=${agrupar}&planoconta=${planoconta}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const RelatorioContasPagarService = {
  RelatorioContasPagar,
  RelatorioContasPagarAgrupadoParceiro,
  RelatorioContasPagarAgrupadoVencimento
};