import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';


import { v4 as uuidv4 } from 'uuid';

type NullableDate = Date | null | undefined;

export interface IListagemVerbas {
    idverbas: string;
    descricao: string;
    valor: string;
    status: boolean;
    fixo: boolean;
    tipovalor: string;
    tipocalculo: string;
    dataexpiracao: Date;
    tipoverba: string;
    id: number;
}

export interface IDetalheVerba {
    descricao: string;
    valor: string;
    status: boolean;
    fixo: boolean;
    tipovalor: string;
    tipocalculo: string;
    dataexpiracao?: NullableDate;
    tipoverba: string;
}

type TotalCount = {
    data: IListagemVerbas[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/verbas?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllList = async (id = '', filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa: string = id === '' ? `/verbaslist?limit=${3}&filter=${filter}` : `/verbaslist/${id}` ;
    const { data, headers } = await Api.get(urlRelativa);
    if (data) {
      return { 
        data,
        totalCount: Number(headers['x-total-count'] || 3),
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheVerba | Error> => {
  try {
    const { data } = await Api.get(`/verbas/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheVerba): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Api.post<IDetalheVerba>('/verbas',  {idverbas: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheVerba): Promise<void | Error> => {
  try {
    await Api.put(`/verbas/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/verbas/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const VerbaService = {
  getAll,
  getAllList,
  create,
  getById,
  updateById,
  deleteById,
};