import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AutoCompleteLocalizacao, AutoCompleteProduto, BasicSelect, IVFormErrors, VFieldMoney, VFieldMoney4, VForm, VTextField, VTextFieldData, VTextFieldNumber, useVForm } from '../../../shared/forms';
import * as yup from 'yup';

type NullableDate = Date | null | undefined;

interface IFormData {
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: NullableDate;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;
  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  status: boolean;
  dados?: IFormData;
  onDados: (dados: IFormData) => void;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'qtdsaida' | 'iditens' | 'descricao' | 'localizacao' | 'filtro'>> = yup.object().shape({
  reqvencimento: yup.boolean().optional().default(false),
  idprod: yup.string().required(),
  idlocalizacao: yup.string().required(),
  validade: yup.date().nullable().optional().default(null).when('reqvencimento', {
    is: true,
    then: () => yup.date().nullable().required('Campo obrigatório').test('valid-date', 'Selecione uma data válida', function (value) {
      const currentDate = new Date();
      if (!value || value < currentDate) return false; 
        
      return true;
    })
  }),
  qtdentrada: yup.number().required().moreThan(0),
  preco: yup.number().required().moreThan(0),
  total: yup.number().required(),
  un: yup.string().required(),
  conversor: yup.number().required().moreThan(0),
});

export const DialogEntrada: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
  const {formRef, save, } = useVForm();
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [isLoading, setIsLoading] = useState(false);  
  const [id, setId] = useState('');
  const guuid = uuidv4();

  const itensnovo  = {
    filtro: 'descricao',
    idprod: '',
    idlocalizacao: '',
    validade: null,
    qtdentrada: '',
    preco: 0,
    total: 0,
    un: '',
    conversor: 1,
  };

  useEffect(() => {
    if(props.id === 'novo'){
      formRef.current?.setData(itensnovo);
    }else{
      if (props.dados && typeof props.dados === 'object') {
        formRef.current?.setData(props.dados); 
      }
    }
  }, [id, formRef]); 

  const handleProduto = (valor: string | undefined) => {
    formRef.current?.setFieldValue('descricao', valor);
  }; 

  const [reqvencimento, setReqvencimento] = useState<boolean | undefined>();
  const handleVencimento = (valor: boolean | undefined) => {
    setReqvencimento(valor);
  }; 
  
  const [localizacaoText, setLocalizacaoText] = useState<string>('');
  const handleLocalizacao = (valor: string) => {
    setLocalizacaoText(valor);
  }; 
        
  const handleSave = (dados: IFormData) => {

    const DadosValidados = {...dados, reqvencimento: reqvencimento};
    
    formValidationSchema.
      validate(DadosValidados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if(props.id === 'novo'){
          props.onDados({iditens: guuid, qtdsaida: 0, descricao: '', localizacao: localizacaoText, filtro:'', ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }else{
          props.onDados({iditens: props.id, qtdsaida: 0, descricao: '', localizacao: localizacaoText, filtro:'', ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};     
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);

  const handleQtd = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ''); 
      const data = formRef.current?.getData();
      const preco = data?.preco;
      const tvalor = (Number(valor) * Number(preco)).toFixed(4);
      formRef.current?.setFieldValue('total', tvalor);
    }
  }; 

  const handleVunitario = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ''); 
      const data = formRef.current?.getData();
      const qtdentrada = data?.qtdentrada;
      const tvalor = (Number(qtdentrada) * Number(valor)).toFixed(4);
      formRef.current?.setFieldValue('total', tvalor);
    }
  }; 

  const [filtro, setFiltro] = useState('descricao');
  const handleFiltro = (valor: string | undefined) => {
    if (valor != null) {
      setFiltro(valor);
    }
  }; 

  return (
    <Dialog
      open={open}
      {...other}
      sx={{ width: '100%'  }}
      maxWidth="md"
    >
      <DialogTitle>Itens</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1.5} >
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <AutoCompleteProduto
                      isExternalLoading={props.status === false ? isLoading : true}
                      descricao={handleProduto}
                      vencimento={handleVencimento}
                      combustivel='false'
                      filtro={filtro}
                    />  
                  </Grid> 
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <BasicSelect
                      name='filtro'
                      label='Filtro'
                      aoClicar={handleFiltro}
                      data={[
                        { nameBD: 'descricao', descricao: 'Descrição' },
                        { nameBD: 'codbarraentrada', descricao: 'Código de Barras Entrada' },
                        { nameBD: 'referencia', descricao: 'Referênca' },
                      ]}
                      disabled={props.status === false ? isLoading : true}
                    />           
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='descricao'
                      label='Ítem'
                      disabled={true}
                    />   
                  </Grid>   
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='referencia'
                      label='Referência'
                      disabled={true}
                    /> 
                  </Grid>  
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <AutoCompleteLocalizacao
                      isExternalLoading={props.status === false ? isLoading : true}
                      localizacaoText={handleLocalizacao}
                    /> 
                  </Grid>        
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <VTextFieldData
                      fullWidth
                      name='validade'
                      label='Data de Validade'
                      disabled={props.status === false ? isLoading : true}
                    />
                  </Grid>
                    
                 
                  <Grid item xs={12} sm={6} md={2.5} lg={2.5} xl={2.5}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='qtdentrada'
                      label='Quantidade'
                      calcular={handleQtd}
                      disabled={props.status === false ? isLoading : true}
                    />
                  </Grid>   
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='un'
                      label='UN'
                      disabled={props.status === false ? isLoading : true}
                    /> 
                  </Grid>   
                  <Grid item xs={6} sm={6} md={1.5} lg={1.5} xl={1.5}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='conversor'
                      label='Conversor'
                      disabled={props.status === false ? isLoading : true}
                    />
                  </Grid>              
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <VFieldMoney4
                      fullWidth
                      size='small'
                      name='preco'
                      label='Valor Unitário'
                      calcular={handleVunitario}
                      disabled={props.status === false ? isLoading : true}
                    />
                  </Grid>    
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <VFieldMoney4
                      fullWidth
                      size='small'
                      name='total'
                      label='Valor Total'
                      disabled={true}
                    />
                  </Grid>     
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' disabled={props.status === false ? isLoading : true} autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}; 