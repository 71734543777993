/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Box, FormGroupClassKey, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, IVFormErrors, VTextFieldData, VFieldMoney, AutoCompleteContaBancaria2  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { LancamentoEntreConstasService } from '../../../shared/services/api/Financeiro/LancamentoEntreConstas';

interface IFormData {
  identrada: number;
  idsaida: number;
  datalan: Date;
  numero: string;
  valor: number;
  historico: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  identrada: yup.number().required(),
  datalan: yup.date().required(),
  numero: yup.string().optional().default(''),
  valor: yup.number().required().moreThan(0.1),
  historico: yup.string().optional().default(''),
  idsaida: yup.number().required().test('idsaida', 'A Conta de Débito não pode ser a mesma da conta de Crédito', function (value, context) {
    const identrada = context.parent.identrada || 0; 
    return value !== identrada && value !== 0;
  }),
});


export const DetalheDeEntreContas: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      LancamentoEntreConstasService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/lancamentoentrecontas');
          } else {
            formRef.current?.setData(result);
            setStatus(true);
          }
        });
    } else {
      formRef.current?.setData({
        datalan: new Date(),
        numero: '',
        valor: 0,
        historico: '',
      });
      setStatus(false);
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          LancamentoEntreConstasService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/lancamentoentrecontas');
                } else {
                  navigate(`/lancamentoentrecontas/detalhe/${result}`);
                }
              }
            });
        } 
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      LancamentoEntreConstasService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/lancamentoentrecontas');
          }
        });
    }
  };
  
  return (
    <LayoutBaseDePagina
      titulo={'Lançamento Entre Contas'}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
          aoClicarEmSalvar={save}
          disableBotaoSalvar={status ? true : isLoading}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/lancamentoentrecontas')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/lancamentoentrecontas/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Dados</Typography>
            </Grid>

            <Grid container item direction="row" spacing={1} >
              <Grid container item direction="row" spacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <AutoCompleteContaBancaria2
                    name='identrada'
                    label='Conta de Credito'
                    isExternalLoading={status ? true : isLoading}
                  />    
                </Grid>    
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <AutoCompleteContaBancaria2
                    name='idsaida'
                    label='Conta de Debito'
                    isExternalLoading={status ? true : isLoading}
                  />    
                </Grid>    
                <Grid item xs={6} sm={12} md={6} lg={3} xl={2}>
                  <VTextFieldData
                    fullWidth
                    name='datalan'
                    label='Data Emissão'
                    disabled={status ? true : isLoading}
                  />         
                </Grid>  
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <VTextField
                    fullWidth
                    size='small'
                    name='numero'
                    disabled={status ? true : isLoading}
                    label='Nº Documento'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6} xl={2}>  
                  <VFieldMoney   
                    fullWidth  
                    size='small'
                    name='valor'
                    label='Valor Cheque'
                    disabled={status ? true : isLoading}
                  />
                </Grid>  

                <Grid item marginTop={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <VTextField
                    fullWidth
                    name='historico'
                    disabled={status ? true : isLoading}
                    multiline
                    rows={2}
                    label='Histórico'
                  />
                </Grid>
              </Grid>
            </Grid>              
          </Grid>  
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};