/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid,  InputLabel,  LinearProgress,  MenuItem,  Paper, Select, Switch } from '@mui/material';
import { useState } from 'react';
import {  AutoCompleteFazenda01, AutoCompleteParceiroListagem, AutoCompletePlanoDeContas01, BasicSelect } from '../../../shared/forms';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { RelatorioContasPagarService } from '../../../shared/services/api/Financeiro/RelatorioContasPagar';
import RelatorioContasPagarAgrupados from './RelatorioContasPagarAgrupado';
import RelatorioContasPagar from './RelatorioContasPagar';
import RelatorioContasPagarVencimentoAgrupado from './RelatorioContasPagarVencimentoAgrupado';
           
export const RelatoriosContasPagar: React.FC = () => {                      
  const [isLoading, setIsLoading] = useState(false);                 
  const [uidparceiro, setUidParceiros] = useState('');      
  const [uidfazenda, setUidFazenda] = useState('');          
  const [uidplanoconta, setUidPlanoconta] = useState('');                
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs());       
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs());      
  const [agrupar, setAgrupar] = useState('nao');      
              
  const handleSave = () => {        
    setIsLoading(true);
    if(agrupar === 'nao'){
      RelatorioContasPagarService                  
        .RelatorioContasPagar(uidparceiro, uidfazenda, uidplanoconta, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))   
        .then((result) => {           
          setIsLoading(false);               
          if (result instanceof Error) {                
            alert(result.message);                
          }else {   
            if(result.length > 0){   
              RelatorioContasPagar(result,  `Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);  
            }else{
              alert('Não a Registros');     
            }   
          }                
        });          
    }else if(agrupar === 'vencimento'){
      RelatorioContasPagarService                  
        .RelatorioContasPagarAgrupadoVencimento(uidparceiro, uidfazenda, agrupar, uidplanoconta, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))   
        .then((result) => {           
          setIsLoading(false);                   
          if (result instanceof Error) {                
            alert(result.message);                     
          }else {   
            if(result.length > 0){   
              RelatorioContasPagarVencimentoAgrupado(result,  `Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);  
            }else{
              alert('Não a Registros'); 
            }   
          }                
        });           
    }else{
      RelatorioContasPagarService                  
        .RelatorioContasPagarAgrupadoParceiro(uidparceiro, uidfazenda, agrupar, uidplanoconta, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))   
        .then((result) => {           
          setIsLoading(false);               
          if (result instanceof Error) {                
            alert(result.message);                
          }else {   
            if(result.length > 0){   
              RelatorioContasPagarAgrupados(result,  `Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);  
            }else{
              alert('Não a Registros'); 
            }   
          }             
        });           
    }   
  };           

  const handleParceiros = (uuidparceiro: string | undefined) => {    
    if (uuidparceiro != null) {     
      setUidParceiros(uuidparceiro);                      
    }else{                       
      setUidParceiros('');                         
    }                 
  };   

  const handleFazenda = (uuidfazenda: string | undefined) => {       
    if (uuidfazenda != null) {     
      setUidFazenda(uuidfazenda);                  
    }else{                       
      setUidFazenda('');                         
    }                 
  };  

  const handlePlanoconta = (planoconta: string | undefined) => {    
    if (planoconta != null) {     
      setUidPlanoconta(planoconta);                  
    }else{                       
      setUidPlanoconta('');                         
    }                 
  };  

  return (
    <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='auto' alignItems="center"  component={Paper} height="auto" margin={1} 
        alignContent='center'>       
        <DialogTitle>Relatório de Contas a Pagar</DialogTitle>
        <DialogContent  dividers>    
          {isLoading && (           
            <Grid item>  
              <LinearProgress variant='indeterminate' />     
            </Grid>                      
          )}   
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteParceiroListagem
                  uuidparceiro={handleParceiros} 
                />                           
              </Grid>    
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>   
                <AutoCompletePlanoDeContas01  
                  uuidplanoconta={handlePlanoconta}   
                />     
              </Grid>    
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>   
                <AutoCompleteFazenda01   
                  uuidfazenda={handleFazenda}           
                />                
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>   
                <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">{'Agrupar por'}</InputLabel>
                  <Select
                    fullWidth
                    size='small'
                    value={agrupar}
                    label='Agrupar por'
                    onChange={(event) => setAgrupar(event.target.value)}
                  >
                    <MenuItem value={'nao'}>Não</MenuItem>
                    <MenuItem value={'vencimento'}>Vencimento</MenuItem>
                    <MenuItem value={'parceiro'}>Parceiro</MenuItem>
                  </Select>  
                </FormControl>         
              </Grid> 
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'   
                    value={dataInicial}         
                    onChange={(newValue) => setDataInicial(newValue)} 
                    label='Data Inicial'  
                    slotProps={{
                      textField: {        
                        size:'small',
                      } 
                    }}   
                  />      
                </LocalizationProvider>
              </Grid>  
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker  
                    format='DD/MM/YYYY'
                    value={dataFinal}
                    onChange={(newValue) => setDataFinal(newValue)} 
                    label='Data Final'
                    slotProps={{
                      textField: {    
                        size:'small',
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>   
            </Grid>        
          </Grid>         
        </DialogContent>     
        <DialogActions>
          <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={handleSave}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   