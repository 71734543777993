import { IFomeTipoPgtoDecimo } from '../../../../../pages/rh';
import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

export interface IDecimo {
  idsalario: string;
  idfun: string;
  nome: string;
  cpf: string;
  admissao: Date;
  scarteira: number;
  salario: number;
  avos: number;
  valorbruto: number;
  inss: number;
  desconto: number;
  valorliquido: number;
}

interface ITipoPgto {
  idsalario: string;
  nome: string;
  cpf: string;
  admissao: Date;
  tipoconta: string;
  agencia: string;
  conta: string;
  dv: string;
  produtor: string;
  numero: string;
  valorliquido: number;
  ctps: string;
  funcao: string;
  ano: number;
  valorbruto: number;
  inss: number;
  desconto: number;
  avos: number;
}

export interface ITipoPgtoChequeDecimo {
  idfolha: string;
  nome: string;
  urlimg: string;
  cpf: string;
  marcado: boolean;
  valorliquido: number;
}

type TotalCount = {
    data: IDecimo[];
    totalCount: number;
}

const GetAll = async (page = 1, tipo: string, ano: number, filter = ''): Promise<TotalCount | Error> => {
  try {
    
    const urlRelativa = `/decimoterceiroano?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}&tipo=${tipo}&ano=${ano}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (ano: string): Promise<string | Error> => {
  try {
    const { data } = await Api.post(`/decimoterceiro/${ano}`);
    if (Object.keys(data).length === 0) { 
      return 'registro ciado com sucesso!';
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const deleteAno = async (ano: string): Promise<void | Error> => {
  try {
    await Api.delete(`/decimoterceiro/${ano}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const updateById = async (id: string, ano: number): Promise<void | Error> => {
  try {
    await Api.put(`/decimoterceiro/${id}/${ano}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const TipoPagamento = async (dados: IFomeTipoPgtoDecimo): Promise<ITipoPgto[] | Error> => {
  try {
    
    const urlRelativa = `/decimoterceiro?ano=${dados.ano}&tipopagamento=${dados.tipopagamento}&fazenda=${dados.idfazenda}&banco=${dados.banco}&tipoconta=${dados.tipoconta}`;

    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const TipoPagamentoCheque = async (ano: number, fazenda: string): Promise<ITipoPgtoChequeDecimo[] | Error> => {
  try {
    
    const urlRelativa = `/decimoterceiro?ano=${ano}&tipopagamento=cheque&fazenda=${fazenda}`;

    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DecimoService = {
  create,
  deleteAno,
  GetAll,
  updateById,
  TipoPagamento,
  TipoPagamentoCheque,
};