/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box,  Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, IVFormErrors, VSwitch, AutoCompleteParceiro, VTextFieldData, VFieldMoney, AutoCompleteProdutor, BasicSelect, AutoCompleteFazenda, AutoCompleteCultivo, VSwitch01, AutoCompletePlanoDeContas  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ContasPagarService } from '../../../shared/services/api/Financeiro/ContasPagar';
import { PatrimoniosService } from '../../../shared/services/api/cadastros/PatrimoniosService';
import { DialogParcelas } from './DialogParcelas';


interface IFormData {
	idparceiro: string;
	idplanoconta: string;
	idfazenda: string;
	idcultivo?: string | null;
	idpatrimonio?: string | null;
	tipopagamento: string;
	dataoperacao: Date;
	dataemissao: Date;
	vencimento: Date;
	parcela: string; 
	numerodoc: string;
	valorparcela: number, 
	valornota: number,
	acrescimo: number,
	valorpago: number,
	desconto: number,
	valorpagar: number,
	idprodutor: number,
	numeronfe: string,
	valornfe: number,
	nfeparcela: number,
	nfepagar: number,
  nfepago: number,
	tipodoc: number,
	historicolcdpr: string;
	historico: string;
	lcdpr: boolean,
  tipo: number,
}

const formValidationSchema : yup.ObjectSchema<Omit<IFormData, 'dataoperacao'>> =  yup.object().shape({
  idparceiro: yup.string().required(),
  idcultivo:  yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idplanoconta: yup.string().required(),
  idpatrimonio:  yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idfazenda:  yup.string().required(),
  tipopagamento: yup.string().required(),
  parcela: yup.string().required(),
  idprodutor: yup.number().optional().default(0).when('lcdpr', {is: true, then: () => yup.string().optional().default('').required('Campo obrigatório') }),
  dataemissao: yup.date().required(),
  vencimento: yup.date().required('O campo é obrigatório')
    .when('$isEditMode', {
      is: false, 
      then: () => yup.date().test('valid-date', 'Não se Lança conta já Vencida', function (value) {
        if (!value) {
          return this.createError({ message: 'Data inválida' });
        }
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() -1);
        return value >= currentDate;
      }),
    }),
  historico: yup.string().optional().default(''),
  lcdpr: yup.boolean().required(),
  historicolcdpr: yup.string().optional().default('').when('lcdpr', {is: true, then: () => yup.string().optional().default('').required('Campo obrigatório') }),
  numerodoc: yup.string().required().max(30),
  numeronfe: yup.string().optional().default('').when('lcdpr', {is: true, then: () => yup.string().optional().default('').required('Campo obrigatório') }),
  valornota: yup.number().required().moreThan(0),
  valorparcela: yup.number().required().test('valorparcela', 'o valor deve ser menor ou igual ao valor total e diferente de zero', function (value, context) {
    const valorcompra = context.parent.valornota || 0; 
    return value <= valorcompra && value !== 0;
  }),
  acrescimo: yup.number().required(),
  desconto: yup.number().required(),
  valorpago: yup.number().required(),
  valorpagar: yup.number().required(),
  valornfe: yup.number().required().when('lcdpr', {is: true, then: () => yup.number().required().moreThan(0)}),
  nfeparcela: yup.number().required().when('lcdpr', {is: true, then: () => yup.number().optional().required().moreThan(0) }).test('nfeparcela', 'o valor deve ser menor ou igual ao valor total e diferente de zero', function (value, context) {
    const valornfe = context.parent.valornfe || 0; 
    return value <= valornfe ;
  }),
  nfepago: yup.number().required(),
  nfepagar: yup.number().required(),
  tipo: yup.number().optional().default(2),
  tipodoc: yup.number().optional().default(0).when('lcdpr', {is: true, then: () => yup.number().optional().required().moreThan(0) }),
});



export const DetalheDePagar: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [statuss, setStatuss] = useState(false);
  const [statusEntrada, setStatusEntrada] = useState(false);
  const [statusPago, setStatusPgo] = useState(false);
  const [statuslcdpr, setStatuslcdpr] = useState(true);
  const [idparceiro, setIdParceiro] = useState('');
  const [pagas, setPagas] = useState(0);
  const [numeroPatrimonio, setNumeroPatrimonio] = useState('');
  const [descricaoPatrimonio, setDescricaoPatrimonio] = useState('');
  const [idpatrimonio, setIdpatrimonio] = useState('');
  const [open, setOpen] = useState(false);
  const [dadosparcelamento, setDadosParcelamento] = useState<IFormData>({} as IFormData);
  const [value, setValue] = useState('');


  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      ContasPagarService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/contaspagar');
          } else {
            setDescricao('Lançamento Contas Pagar');
            formRef.current?.setData(result);
            setDescricaoPatrimonio(result.patrimonio);
            setNumeroPatrimonio(result.npatrimonio);
            setIdParceiro(result.idparceiro);
            if (result.idpatrimonio != null) {
              setIdpatrimonio(result.idpatrimonio);
            }
            setStatuss(result.status);  
            setPagas(result.pagas);
            if(result.tipo == 1){
              setStatusEntrada(true);
            }else{
              setStatusEntrada(false); 
            }
            if(result.pagas > 0){
              setStatusPgo(true);
            }else{
              setStatusPgo(false); 
            }
          }
        });
    } else {
      formRef.current?.setData({
        tipopagamento: 'Carteira',
        dataoperacao: new Date(),
        dataemissao: new Date(),
        vencimento: new Date(),
        parcela: '1',
        numerodoc: '',
        valorparcela: 0,
        valornota: 0,
        acrescimo: 0,
        valorpago: 0,
        desconto: 0,
        valorpagar: 0,
        numeronfe: '',
        valornfe: 0,
        nfeparcela: 0,
        nfepago: 0,
        nfepagar: 0,
        historicolcdpr: '',
        historico: '',
        lcdpr: true,
        tipodoc: 0,
      });
    }
  }, [id]);
  
  const handleSave = (dados: IFormData) => {
    dados.idpatrimonio = idpatrimonio || null;
    const validationContext = { isEditMode: id === 'novo' ? false : true};
    formValidationSchema.
      validate(dados, {context: validationContext , abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          if (Number(dados.parcela) > 1){
            const total = dados.valornota;
            setDadosParcelamento(dados);
            setOpen(true);
            setIsLoading(false);
          }else{
            ContasPagarService
              .create(dadosValidados)
              .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {  
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    navigate('/contaspagar');
                  } else {
                    navigate(`/contaspagar/detalhe/${result}`);
                  }
                }
              });
          }} else {
          ContasPagarService
            .updateById(id, {pagas: pagas, status: statuss, ...dadosValidados})
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/contaspagar');
                }
              }
            });
        }
      }).catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ContasPagarService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/contaspagar');
          }
        });
    }
  };

  const handlecalcularParcela = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const acrescimo = data?.acrescimo;
      const desconto= data?.desconto;
      const valorpago= data?.valorpago;  
      const tparcela = Number(valor);
      const valorpagar = tparcela +  Number(acrescimo) - Number(desconto) - Number(valorpago);
      formRef.current?.setFieldValue('valorpagar', valorpagar);
    }
  };

  const handlecalcularTotal = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const acrescimo = data?.acrescimo;
      const desconto= data?.desconto;
      const valorpago= data?.valorpago;  
      const tparcela = Number(valor);
      const valorpagar = tparcela +  Number(acrescimo) - Number(desconto) - Number(valorpago);
      formRef.current?.setFieldValue('valorpagar', valorpagar);
      formRef.current?.setFieldValue('valorparcela', valorpagar);
    }
  };

  const handlecalcularAcescimo = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const parcela = data?.valorparcela;
      const desconto= data?.desconto;
      const valorpago = data?.valorpago;  
      const acrescimo = Number(valor);
      const vpagar = acrescimo +  Number(parcela) - Number(desconto) - Number(valorpago);
      formRef.current?.setFieldValue('valorpagar', vpagar);
    }
  };

  const handlecalcularDesconto = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const parcela = data?.valorparcela;
      const acrescimo = data?.acrescimo;
      const valorpago = data?.valorpago;  
      const desconto = Number(valor);
      const vpagar =  Number(parcela) + Number(acrescimo) - desconto - Number(valorpago);
      formRef.current?.setFieldValue('valorpagar', vpagar);
    }
  };

  const handlecalcularParcelaNfe = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const nfepago = data?.nfepago;
      const vpagar =  Number(valor) - Number(nfepago);
      formRef.current?.setFieldValue('nfepagar', vpagar);
    }
  };

  const handlecalcularTotalNfe = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const nfepago = data?.nfepago;
      const vpagar =  Number(valor) - Number(nfepago);
      formRef.current?.setFieldValue('nfepagar', vpagar);
      formRef.current?.setFieldValue('nfeparcela', vpagar);
    }
  };

  const handleSwitch = (checkeds: boolean) => {
    setStatuslcdpr(checkeds);
    if(!checkeds){
      formRef.current?.setFieldValue('historicolcdpr', ''); 
      formRef.current?.setFieldValue('numeronfe', ''); 
      formRef.current?.setFieldValue('tipodoc', 0); 
      formRef.current?.setFieldValue('valornfe', 0); 
      formRef.current?.setFieldValue('nfeparcela', 0); 
      formRef.current?.setFieldValue('nfereceber', 0); 
      formRef.current?.setFieldValue('nferecebido', 0); 
    }
  };

  const handlebusca = (numeroPatrimonios: string) => {
    setIsLoading(true);
    if (numeroPatrimonios) {
 
      PatrimoniosService.getAllListNumero(numeroPatrimonios)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setDescricaoPatrimonio(result.descricao);
            setIdpatrimonio(result.idpatrimonio);
          }
        });
    }
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Lançamento Contas Pagar' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/contaspagar')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/contaspagar/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
          disableBotaoApagar={statuss}
          disableBotaoSalvar={statuss}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Dados</Typography>
            </Grid>

            <Grid container item direction="row"  spacing={1}>  
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                <Grid container item direction="row" spacing={2} >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <AutoCompleteParceiro
                      isExternalLoading={statuss || statusEntrada || statusPago ? true : isLoading}
                    />  
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <AutoCompletePlanoDeContas 
                      isExternalLoading={statuss || statusEntrada || statusPago ? true : isLoading}
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <AutoCompleteFazenda
                      isExternalLoading={statuss || statusEntrada || statusPago ? true : isLoading}
                    />    
                  </Grid>     
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                    <AutoCompleteCultivo
                      isExternalLoading={statuss || statusEntrada ? true : isLoading}
                    />    
                  </Grid>  
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <TextField
                      fullWidth  
                      size='small'
                      value={numeroPatrimonio}
                      label='Nº Patrimônio'
                      disabled={statuss ? true : isLoading}
                      onChange={e => setNumeroPatrimonio(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && numeroPatrimonio.trim() !== '') {
                          handlebusca(numeroPatrimonio);
                        }
                      }}
                    />              
                  </Grid> 
                  <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>     
                    <TextField
                      fullWidth
                      size='small'
                      value={descricaoPatrimonio}
                      disabled={true}
                      label='Patrimônio'
                    />   
                  </Grid>
                  
                  <Grid item xs={8} sm={8} md={2.5} lg={3} xl={3}>
                    <BasicSelect
                      name='tipopagamento' 
                      label='Forma de Pagamento'
                      data={[
                        { nameBD: 'Boleto', descricao: 'Boleto' },
                        { nameBD: 'Carteira', descricao: 'Carteira' },
                        { nameBD: 'Cartão Agro-Negocio', descricao: 'Cartão Agro-Negócio' },
                        { nameBD: 'Depósito', descricao: 'Depósito' },
                        { nameBD: 'Debito em conta', descricao: 'Débito em conta' },
                        { nameBD: 'pix', descricao: 'PIX' },
                      ]}
                      disabled={statuss ? true : isLoading}
                    />
                  </Grid>           
                  <Grid item xs={4} sm={4} md={1.5} lg={1} xl={1}>
                    {id === 'novo' ? (
                      <BasicSelect
                        name='parcela'
                        label='Parcela'
                        data={[
                          { nameBD: '1', descricao: '1' },
                          { nameBD: '2', descricao: '2' },
                          { nameBD: '3', descricao: '3' },
                          { nameBD: '4', descricao: '4' },
                          { nameBD: '5', descricao: '5' },
                          { nameBD: '6', descricao: '6' },
                          { nameBD: '7', descricao: '7' },
                          { nameBD: '8', descricao: '8' },
                          { nameBD: '9', descricao: '9' },
                          { nameBD: '10', descricao: '10' },
                          { nameBD: '11', descricao: '11' },
                          { nameBD: '12', descricao: '12' },
                        ]}
                        disabled={isLoading}
                      />
                    ) : (
                      <VTextField
                        fullWidth
                        size='small'
                        name='parcela'
                        label='Parcela'
                        disabled={true}
                      />
                    )}
                  </Grid>      
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='numerodoc'
                      label='Numero Documento'           
                      disabled={statuss || statusEntrada || statusPago  ? true : isLoading}
                    />     
                  </Grid>  
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='dataoperacao'  
                      label='Data Operação'
                      disabled={true}
                    />
                  </Grid>      
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='dataemissao'
                      label='Emissão'
                      disabled={statuss || statusEntrada || statusPago ? true : isLoading}
                    />         
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='vencimento'
                      label='Vencimento'
                      disabled={statuss || statusEntrada ? true : isLoading}
                    />         
                  </Grid>  
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='valornota'
                      label='Valor Total'
                      calcular={handlecalcularTotal}
                      disabled={statuss || statusEntrada || statusPago  ? true : isLoading}
                    />
                  </Grid>    
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='valorparcela'
                      label='Valor Parcela'
                      calcular={handlecalcularParcela}
                      disabled={statuss || statusEntrada || statusPago  ? true : isLoading}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='acrescimo'
                      label='Acré. / Juros'
                      calcular={handlecalcularAcescimo}
                      disabled={statuss  ? true : isLoading}
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='desconto'
                      label='Desconto'
                      calcular={handlecalcularDesconto}
                      disabled={statuss  ? true : isLoading}
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='valorpago'
                      label='Valor Pago'
                      disabled={true}
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='valorpagar'
                      label='Valor Pagar'
                      disabled={true}
                    />
                  </Grid>     
                 
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <AutoCompleteProdutor
                      isExternalLoading={statuss || statusEntrada || statusPago || !statuslcdpr ? true : isLoading}
                    />                 
                  </Grid>  
                  <Grid item xs={6} sm={12} md={2} lg={2} xl={1}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='numeronfe'
                      label='Numero NFE'           
                      disabled={statuss || statusEntrada || statusPago || !statuslcdpr ? true : isLoading}
                    />     
                  </Grid>     
                           
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth
                      size='small'  
                      name='valornfe'
                      label='Valor NFE'
                      calcular={handlecalcularTotalNfe}
                      disabled={statuss || statusEntrada || statusPago || !statuslcdpr ? true : isLoading}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='nfeparcela'
                      label='NFE Parcela'
                      calcular={handlecalcularParcelaNfe}
                      disabled={statuss || statusEntrada || statusPago || !statuslcdpr ? true : isLoading}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='nfepago'
                      label='NFE Pago'
                      disabled={true}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={1.5} lg={1.5} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='nfepagar'
                      label='NFE Pagar'
                      disabled={true}
                    />
                  </Grid> 
                  <Grid item xs={6} sm={12} md={2.5} lg={2.5} xl={3}>
                    <BasicSelect
                      name='tipodoc' 
                      label='Tipo Documento'
                      data={[
                        { nameBD: '0', descricao: '' },
                        { nameBD: '1', descricao: 'Nota Fiscal' },
                        { nameBD: '2', descricao: 'Fatura' },
                        { nameBD: '3', descricao: 'Recibo' },
                        { nameBD: '4', descricao: 'Contrato' },
                        { nameBD: '5', descricao: 'Folha de Pagamento' },
                        { nameBD: '6', descricao: 'Outros' },
                      ]}
                      disabled={statuss || statusEntrada || statusPago || !statuslcdpr ? true : isLoading}
                    />
                  </Grid>       
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='historicolcdpr'
                      disabled={statuss  || !statuslcdpr ? true : isLoading}
                      label='Histório LCDPR'
                    />
                  </Grid> 
                  <Grid item marginTop={1} xs={12} sm={12} md={1} lg={1} xl={1}>  
                    <VSwitch01
                      name='lcdpr'
                      label='LCDPR'
                      onFCheckedChange={handleSwitch}
                      fdisabled={statuss || statusEntrada || statusPago ? true : isLoading}/>
                  </Grid> 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='historico'
                      disabled={isLoading}
                      multiline
                      rows={3}
                      label='Histório'
                    />
                  </Grid>
                </Grid>
              </Grid>  
            </Grid> 
            <DialogParcelas
              dados={dadosparcelamento}
              value={''}  
              open={open} 
              onClose={handleClose}
            />              
          </Grid>  
        </Box>
      </VForm> 
    </LayoutBaseDePagina>
  );
};