import {  Box, Button, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid,  LinearProgress, Paper, Switch } from '@mui/material';
import { useState } from 'react';
import {  AutoCompleteCultivo01, AutoCompleteFazenda01} from '../../../shared/forms';
import { RelatorioRomaneiosService } from '../../../shared/services/api/faturamento/romaneios/RRelatorios';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RProducaoResumido from './RProducaoResumido';
import RRelatorioProducaoDetalhes from './RRelatorioProducaoDetalhes';
           
export const RelatoriosProducao: React.FC = () => {                      
  const [isLoading, setIsLoading] = useState(false);      
  const [uidfazenda, setUidFazenda] = useState('');      
  const [uidcultivo, setUidCultivo] = useState('');           
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs());      
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 
  const [detalhes, setDetalhes] = useState(false);       
                    
  const handleSave = () => {            
    if(detalhes){
      RelatorioRomaneiosService                   
        .RProducaoDetalhada(uidfazenda, uidcultivo, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'), detalhes)   
        .then((result) => {           
          setIsLoading(false);               
          if (result instanceof Error) {                               
            alert(result.message);               
          }else { 
            if(result.length > 0){
              RRelatorioProducaoDetalhes(result, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'));    
            }else{
              alert('Não a Registros'); 
            }              
          }          
        });    
    }else{
      RelatorioRomaneiosService                   
        .RProducaoResumido(uidfazenda, uidcultivo, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'), detalhes)   
        .then((result) => {           
          setIsLoading(false);         
          if (result instanceof Error) {                           
            alert(result.message);               
          }else {   
            if(result.length > 0){
              RProducaoResumido(result, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'));       
            }else{
              alert('Não a Registros');   
            }             
          }          
        });    
    }               
  };    
  const handleFazenda = (uuidfazenda: string | undefined) => {
    if (uuidfazenda != null) {
      setUidFazenda(uuidfazenda);
    }else{
      setUidFazenda('');
    }
  };

  const handleCultivo = (uuidcultivo: string | undefined) => {
    if (uuidcultivo != null) {
      setUidCultivo(uuidcultivo);        
    }else{
      setUidCultivo('');        
    }
  };

  return (
    <Box   alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
      <Box gap={1}   padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" alignContent='center'></Box>
      <Box    width='auto' alignItems="center"  component={Paper} height="auto"
        alignContent='center'>       
        <DialogTitle>Relatório de Produção</DialogTitle>
        <DialogContent  dividers>
          
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />  
            </Grid>                   
          )}   
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteFazenda01
                  uuidfazenda={handleFazenda} 
                />             
              </Grid> 
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoCompleteCultivo01
                  uuidCultivo={handleCultivo} 
                />             
              </Grid> 
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={dataInicial}
                    onChange={(newValue) => setDataInicial(newValue)} 
                    slotProps={{
                      textField: {    
                        size:'small',
                      }
                    }}
                  />
                </LocalizationProvider>
        
              </Grid>  
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={dataFinal}
                    onChange={(newValue) => setDataFinal(newValue)} 
                    slotProps={{
                      textField: {    
                        size:'small',
                      }
                    }}
                  />
                </LocalizationProvider>   
              </Grid>     
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <FormControlLabel   
                  labelPlacement="top"
                  control={
                    <Switch 
                      size='small'
                      checked={detalhes}
                      onChange={(e) => setDetalhes(e.target.checked)} 
                    />    
                  } 
                  label = 'Detalhes'
                />         
              </Grid>          
            </Grid>        
          </Grid>         
        </DialogContent>     
        <DialogActions>
          <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={handleSave}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   