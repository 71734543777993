
export const menurh =  [
  {
    icon: 'dashboard_customize',
    path: '/dashboardrh',
    label: 'Pagina Inicial',
  },
  {
    icon: 'factory',
    path: '/cei',
    label: 'Cei',
  },
  {
    icon: 'alarm',
    path: '/ponto',
    label: 'Ponto',
  },
  {
    icon: 'currency_exchange',
    path: '/vales',
    label: 'Adiantamento',
  },
  {
    icon: 'engineering',
    path: '/funcionarios',
    label: 'Colaborador',
  },
  {
    icon: 'settings',
    path: '/verbas',
    label: 'Verbas',
  },
  {
    icon: 'monetization_on',
    path: '/gratificacoes',
    label: 'Gratificação',
  },
  {
    icon: 'engineering',
    path: '/gerafolha',
    label: 'Gera Folha',
  },
  {
    icon: 'receipt_long',
    path: '/folha',
    label: 'Folha',
  },
  {
    icon: 'settings',
    path: '/configuracoes/1',
    label: 'Configuraçoes',
  },
  {
    icon: 'integration_instructions',
    path: '/statuscolaborador',
    label: 'Status',
  },
  {
    icon: 'savings',
    path: '/geradecimo',
    label: 'Gera Decimo Terceiro',
  },
  {
    icon: 'receipt',
    path: '/listagemdecimo',
    label: 'Lista Decimo Terceiro',
  },
];

export const menurhrelatorio =  [
  {
    icon: 'print',
    path: '/rhtipopagamento',
    label: 'Tipo Pagto',
  },
  {
    icon: 'print',
    path: '/faltacei',
    label: 'Falta Por Cei',
  },
  {
    icon: 'print',
    path: '/recibopagamento',
    label: 'Recibo',
  },
  {
    icon: 'print',
    path: '/aniversariante',
    label: 'Aniversariante',
  },
  {
    icon: 'print',
    path: '/segtrabalho',
    label: 'Seg. Trabalho',
  },
  {
    icon: 'print',
    path: '/exportfolha',
    label: 'Exportar Folha',
  },
  {
    icon: 'print',
    path: '/rhtipopagamentodecimo', 
    label: 'Tipo Pgto Decimo',
  },
  {
    icon: 'print',
    path: '/recibodecimo',
    label: 'Recibo Decimo Terceiro',
  },
  {
    icon: 'print',
    path: '/packing',
    label: 'Packing',
  }
]; 