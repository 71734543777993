import { Environment } from '../../../../environment';
import { dataextenso } from '../../../util/dataextenso';
import { Api } from '../../axios-config';
import { v4 as uuidv4 } from 'uuid';

interface IFormDataGet {
  idponto: string;
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  idfazenda: string;
  fazenda: string;
  status: boolean;
}

interface IDetalhesPonto{
  idponto: string;
  dia: string;
  semana: string;
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  fazenda: string;
  status: boolean;
}

interface IResumoPonto{
    presenca: number;
    falta: number;
    dsr: string;
    horaextra: number;
    bomba: number;
    pistola: number;
    atestado: number;
    domingo: number;
    alimentacao: number;
    detalhes: IDetalhesPonto[];
}

interface IFormData {
    ponto: string;
    horaextra: number;
    pistola: number;
    bomba: number;
    cafe: number;
    almoco: number;
    janta: number;
    idfazenda: string;
  }

  interface IFormDataCreate {
    ponto: string;
    idfun: string;
    horaextra: number;
    pistola: number;
    bomba: number;
    cafe: number;
    almoco: number;
    janta: number;
    idfazenda: string;
    datalan: Date;
  }


type TpontoTotalCount = {
    data: IResumoPonto[];   
    totalCount: number;
}

function isSunday(data: Date): boolean {
  return data.getDay() != 0;
}

const getAll = async (page = 1, idfun: string, periodo: string): Promise<TpontoTotalCount | Error> => {
  try {
     
    const urlRelativa = `/pontos?page=${page}&limit=${31}&idfun=${idfun}&periodo=${periodo}`;

    const { data, headers } = await Api.get(urlRelativa);


    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getById = async (id: string): Promise<IFormDataGet | Error> => {
  try {
    const { data } = await Api.get(`/pontos/${id}`);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IFormDataCreate): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    if(isSunday(dados.datalan)){
      if(dados.ponto === 'D'){
        return new Error('D somente em Domingo'); 
      }
    }


    const pontoMapping = {
      'P': { presenca: 1, falta: 0, atestado: 0, domingo: 0 },
      'F': { presenca: 0, falta: 1, atestado: 0, domingo: 0 },
      'A': { presenca: 0, falta: 0, atestado: 1, domingo: 0 },
      'M': { presenca: 0.5, falta: 0.5, atestado: 1, domingo: 0 },
      'D': { presenca: 0, falta: 0, atestado: 0, domingo: 1 },
    };
    
    const selectedMapping = pontoMapping[dados.ponto as keyof typeof pontoMapping];
    
    const ponto = {
      idponto:  guuid,
      idfun: dados.idfun,
      periodo: dataextenso(dados.datalan),
      idfazenda: dados.idfazenda,
      datalan: dados.datalan,
      presenca: selectedMapping.presenca,
      pistola: dados.pistola,
      horaextra: dados.horaextra,
      bomba: dados.bomba,
      almoco: dados.almoco,
      janta: dados.janta,
      cafe: dados.cafe,
      falta: selectedMapping.falta,
      atestado: selectedMapping.atestado,
      domingo: selectedMapping.domingo,
    };

    const { data } = await Api.post<IFormDataCreate>('/pontos',  ponto);
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados:IFormData): Promise<void | Error> => {
  try {
    await Api.put(`/pontos/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/pontos/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const PontoService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};